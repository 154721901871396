import React, { ReactElement, memo } from 'react';

// Components
import OverviewSquare from './overviewSquare';

// Types
import { APIGetGetGeneralAnalyticsResponse } from '../../utils/apiTypes';
import { Skeleton } from 'antd';

interface Props {
  data: APIGetGetGeneralAnalyticsResponse;
}

const SquareWrapper = ({ data }: Props): ReactElement => {
  return (
    <>
      {data.counts
        ? Object.values(data.counts).map((squareData, index) => (
            <OverviewSquare key={`overview-square-item-${index}`} data={squareData} index={index} />
          ))
        : [1, 2, 3, 4].map((squareData, index) => (
            <div key={`overview-square-item-loading-${index}`} className="analytics-page__overview-square">
              <Skeleton active paragraph={{ rows: 4 }} />
            </div>
          ))}
    </>
  );
};

export default memo(SquareWrapper);
