import { FC, ReactElement } from 'react';
import { store } from 'react-notifications-component';

const NOTIFICATION_DURATION = 5000;
const NOTIFICATION_WIDTH = 380;

const dispatchNotification = (
  title: string,
  message: string | FC,
  type: 'success' | 'danger' | 'info' | 'default' | 'warning' = 'default',
  content: ReactElement | null = null,
): void =>
  store.addNotification({
    title,
    message,
    type,
    content,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    width: NOTIFICATION_WIDTH,
    dismiss: {
      duration: NOTIFICATION_DURATION,
      pauseOnHover: true,
    },
    slidingEnter: {
      duration: 300,
      timingFunction: 'linear',
      delay: 0,
    },

    slidingExit: {
      duration: 300,
      timingFunction: 'linear',
      delay: 0,
    },
  });

export default dispatchNotification;
