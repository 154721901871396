import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { withRouter } from 'react-router-dom';

import { Dropdown, Menu, Switch } from 'antd';

import { toggleBar } from '../../actions/navBarActions';
import { toggleDarkMode } from '../../actions/appActions';

import Loader from '../loader';
import avatar from '../../assets/images/user-image.jpg';

import { AppActions } from '../../types/actionsTypes';
import { NavBarTypes } from '../../reducers/navBarReducer';
import { AppState } from '../../store';
import routesPath, { HOME, ANALYTICS, SETTINGS, INSIGHTS, DEVICES } from '../../constants/routes';

import './navBarTop.sass';

type Props = {
  toggleBar: typeof toggleBar;
  toggleDarkMode: typeof toggleDarkMode;
  navBar: NavBarTypes;
  location: { pathname: string };
  darkMode: boolean;
};

class NavBarTop extends Component<Props> {
  getPageName = (): string => {
    const array = this.props.location.pathname.split('/');
    const location = `/${array[1]}`;
    switch (location) {
      case routesPath.HOME:
        return HOME;
      case routesPath.ANALYTICS:
        return ANALYTICS;
      case routesPath.SETTINGS:
        return SETTINGS;
      case routesPath.INSIGHTS:
        return INSIGHTS;
      case routesPath.DEVICES:
        return DEVICES;
      default:
        return '';
    }
  };

  toggleDarkMode = (toggled: boolean): void => {
    this.props.toggleDarkMode(toggled);
  };
  render(): {} {
    const {
      toggleBar,
      navBar: { expanded, globalLoading },
      darkMode,
    } = this.props;
    return (
      <div className="nav-bar-top">
        <div className={`nav-bar-top__tog${expanded ? ' nav-bar-top__tog--expand' : ''}`}>
          <div role="button" tabIndex={0} className="nav-bar-top__tog_icon" onClick={toggleBar}>
            <Loader loading={globalLoading} className={`icon-dots${expanded ? ' icon-dots--rotate' : ''}`} />
          </div>
        </div>
        <div className="nav-bar-top__main">
          <h2>{this.getPageName()}</h2>
          <div className="nav-bar-top__avatar">
            <div className="nav-bar-top__avatar_name">Mark</div>
            <Dropdown
              overlay={
                <Menu>
                  <div style={{ padding: 5 }}>
                    <span style={{ marginRight: 10 }}>Dark mode</span>
                    <Switch
                      checked={darkMode}
                      onClick={this.toggleDarkMode}
                      checkedChildren={<span>ON</span>}
                      unCheckedChildren={<span>OFF</span>}
                      defaultChecked
                    />
                  </div>
                </Menu>
              }
            >
              <div className="nav-bar-top__avatar_clip">
                <img src={avatar} alt="user-avatar" />
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    );
  }
}

type LinkStateProps = {
  navBar: NavBarTypes;
  darkMode: boolean;
};

type LinkDispatchProps = {
  toggleBar: () => AppActions;
  toggleDarkMode: (state: boolean) => AppActions;
};

const mapStateToProps = (state: AppState): LinkStateProps => ({
  navBar: state.navBar,
  darkMode: state.app.darkMode,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<unknown, unknown, AppActions>): LinkDispatchProps => ({
  toggleBar: bindActionCreators(toggleBar, dispatch),
  toggleDarkMode: bindActionCreators(toggleDarkMode, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBarTop));
