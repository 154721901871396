import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

// Libraries

// Actions
import {
  adminGetUsers,
  adminCreateUser,
  adminUpdateUser,
  adminDeleteUser,
  addPermissionsToUser,
} from '../../../actions/adminActions';

// Components
import CreateUser from './createUser';
import UsersTable from './usersTable';

// Types
import { AppState } from '../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { AppActions } from '../../../types/actionsTypes';
import { bindActionCreators } from 'redux';
import { AdminUserObject } from '../../../reducers/adminReducer';

import '../settingsPage.sass';

interface Props {
  users: AdminUserObject[];
  getUsers?: () => Promise<AdminUserObject>;
  createUser?: (data: object) => Promise<AdminUserObject>;
  adminUpdateUser?: (userId: string | number, data: Credentials) => Promise<AdminUserObject>;
  adminDeleteUser?: (userId: string | number) => Promise<AdminUserObject>;
  addPermissionsToUser?: (userId: number, permissions: string[] | null[]) => Promise<AdminUserObject>;
  allPermissions: {
    id: number;
    name: string;
  }[];
}

export interface Credentials {
  username: string;
  password?: string;
  passwordConfirmation?: string;
}

export interface Permissions {
  [name: string]: boolean;
}

export interface UserState {
  credentials: Credentials;
  permissions: Permissions;
}

class AdminSettings extends PureComponent<Props> {
  render(): {} {
    const {
      users,
      allPermissions,
      getUsers,
      adminDeleteUser,
      adminUpdateUser,
      addPermissionsToUser,
      createUser,
    } = this.props;

    return (
      <div className="settings-page_admin">
        <CreateUser
          createUser={createUser}
          allPermissions={allPermissions}
          addPermissionsToUser={addPermissionsToUser}
        />
        <UsersTable
          users={users}
          getUsers={getUsers}
          deleteUser={adminDeleteUser}
          allPermissions={allPermissions}
          saveEditedUser={adminUpdateUser}
          editPermissions={addPermissionsToUser}
        />
      </div>
    );
  }
}

type LinkStateProps = {
  users: AdminUserObject[];
  allPermissions: {
    id: number;
    name: string;
  }[];
};

const mapStateToProps = (state: AppState): LinkStateProps => ({
  users: state.admin.users,
  allPermissions: state.auth.user ? state.auth.user.permissions : [],
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, void, AppActions>): {} => ({
  getUsers: bindActionCreators(adminGetUsers, dispatch),
  createUser: bindActionCreators(adminCreateUser, dispatch),
  adminUpdateUser: bindActionCreators(adminUpdateUser, dispatch),
  adminDeleteUser: bindActionCreators(adminDeleteUser, dispatch),
  addPermissionsToUser: bindActionCreators(addPermissionsToUser, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminSettings);
