import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

// Libraries

// Actions

// Components

// Types

import '../settingsPage.sass';

class UserSettings extends PureComponent {
  render(): {} {
    return (
      <div className="settings-page_user">
        <h3>user settings</h3>
      </div>
    );
  }
}

// type LinkStateProps = {};

const mapStateToProps = (): {} => ({});

const mapDispatchToProps = (): {} => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
