import {
  ADD_PERMISSIONS_TO_USER,
  ADMIN_CREATE_USER,
  ADMIN_DELETE_USER,
  ADMIN_GET_USERS,
  ADMIN_UPDATE_USER,
  ADD_SERIAL_NUMBERS_TO_USER,
  DELETE_SERIAL_NUMBERS_FROM_USER,
} from '../constants/actionTypes';
import { AdminActionTypes } from '../types/adminActionTypes';

export interface AdminTypes {
  users: AdminUserObject[];
}

export interface AdminUserObject {
  id: number;
  role: string;
  username: string;
  phone: string | null;
  email: string;
  liveUpdate: boolean;
  permissions: {
    id: number;
    name: string;
  }[];
  serialNumbers: {
    id: number;
    number: string;
  }[];
}

const initialState: AdminTypes = {
  users: [],
};

export default function adminReducer(state = initialState, action: AdminActionTypes): AdminTypes {
  switch (action.type) {
    case ADMIN_GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case ADMIN_CREATE_USER:
      return {
        ...state,
        users: [...state.users, action.payload],
      };
    case ADMIN_UPDATE_USER: {
      const newData = [...state.users];
      const index = newData.findIndex(item => item.id === action.payload.id);
      newData[index] = { ...newData[index], ...action.payload };

      return {
        ...state,
        users: newData,
      };
    }
    case ADMIN_DELETE_USER: {
      const newData = [...state.users];
      const index = newData.findIndex(item => item.id === action.payload.id);
      newData.splice(index, 1);

      return {
        ...state,
        users: newData,
      };
    }
    case ADD_PERMISSIONS_TO_USER: {
      const newUsers = [...state.users];
      const index = newUsers.findIndex(user => user.id === action.payload.id);
      newUsers[index].permissions = action.payload.permissions;
      return {
        ...state,
        users: newUsers,
      };
    }
    case ADD_SERIAL_NUMBERS_TO_USER: {
      const newData = [...state.users];
      const index = newData.findIndex(user => user.id === action.payload.id);
      newData[index] = { ...newData[index], ...action.payload };

      return {
        ...state,
        users: newData,
      };
    }
    case DELETE_SERIAL_NUMBERS_FROM_USER: {
      const newData = [...state.users];
      const index = newData.findIndex(user => user.id === action.payload.id);
      newData[index] = { ...newData[index], ...action.payload };

      return {
        ...state,
        users: newData,
      };
    }
    default:
      return state;
  }
}
