import React, { memo, ReactElement, useCallback, useEffect, useState } from 'react';

// Libraries
import moment from 'moment';
import { Avatar, Icon, Table, Tag } from 'antd';

// Components
import ExpandComponent from './expandComponent';

// Types
import { AdminUserObject } from '../../../reducers/adminReducer';

// Assets
import avatar from '../../../assets/images/user-image.jpg';
import { Credentials } from './adminSettings';

const CircleComponent = memo(
  ({ permissionName, record }: { permissionName: string; record: string[] }): ReactElement => {
    return record.includes(permissionName) ? (
      <Avatar
        style={{
          background: '#1abc9c',
          color: 'white',
          fontSize: 12,
          width: 23,
          height: 23,
          lineHeight: '23px',
        }}
        icon="check"
      />
    ) : (
      <Avatar
        style={{ background: '#cfdae6', color: 'white', fontSize: 12, width: 23, height: 23, lineHeight: '23px' }}
        icon="close"
      />
    );
  },
);

interface Props {
  users: AdminUserObject[];
  getUsers?: () => Promise<AdminUserObject>;
  allPermissions: { id: number; name: string }[];
  saveEditedUser?: (id: number | string, data: Credentials) => Promise<AdminUserObject>;
  deleteUser?: (id: string | number) => void;
  editPermissions?: (id: number, permissions: string[] | null[]) => Promise<AdminUserObject>;
}

const UsersTable = ({
  users,
  getUsers,
  allPermissions,
  saveEditedUser,
  deleteUser,
  editPermissions,
}: Props): ReactElement => {
  const [expandedData, setExpandedData] = useState<AdminUserObject | null>(null);
  const [expandedRowKey, setExpandedRowKey] = useState<number[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(true);

  useEffect(() => {
    getUsers && getUsers().then(() => setTableLoading(false));
  }, []);

  useEffect(() => {
    if (expandedData) {
      setExpandedData(users.find(user => user.id === expandedData.id) as AdminUserObject);
    }
  });

  const onExpand = useCallback((expanded: boolean, data: AdminUserObject): void => {
    if (expanded) {
      setExpandedData(data);
      setExpandedRowKey([data.id]);
    } else {
      setExpandedData(null);
      setExpandedData(null);
      setExpandedRowKey([]);
    }
  }, []);

  const deleteUserOnClick = useCallback((id: number | string): void => {
    deleteUser && deleteUser(id);
  }, []);

  const columns = [
    {
      title: 'User name',
      dataIndex: 'username',
      key: 'username',
      render: (record: string): ReactElement => {
        return (
          <div className="table-component__user-column">
            {' '}
            <Avatar src={avatar} />
            <span>{record}</span>
          </div>
        );
      },
      sorter: (a: { username: string }, b: { username: string }): number => a.username.localeCompare(b.username),
      width: '300px',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (record: string): ReactElement => (
        <div>{record === 'admin' ? <Tag color="red">{record}</Tag> : <Tag color="green">{record}</Tag>}</div>
      ),
      filters: [
        {
          text: 'Admin',
          value: 'Admin',
        },
        {
          text: 'User',
          value: 'User',
        },
      ],
      width: '100px',
      onFilter: (value: string, record: { role: string }): boolean => record.role.indexOf(value) === 0,
      sorter: (a: { role: string }, b: { role: string }): number => a.role.length - b.role.length,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record: string): string => moment(record, 'YYYY-MM-DD HH-mm-ss').format('YYYY-MM-DD HH-mm-ss'),
    },
    ...allPermissions.map(per => ({
      title:
        per.name
          .split('-')
          .join(' ')
          .charAt(0)
          .toUpperCase() +
        per.name
          .split('-')
          .join(' ')
          .slice(1),
      dataIndex: 'permissions',
      key: per.name,
      render: (record: { name: string }[], data: AdminUserObject): ReactElement | string => {
        if (data.role === 'admin') return '';
        return <CircleComponent permissionName={per.name} record={record.map(e => e.name)} />;
      },
      width: '120px',
    })),
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      width: '80px',
      render: (record: boolean, data: AdminUserObject): ReactElement => (
        <div className="settings-page__actions-wrapper">
          <Icon
            style={{ outline: 'none' }}
            onClick={(): void => onExpand(expandedRowKey[0] !== data.id, data)}
            type="edit"
          />
          <Icon onClick={(): void => deleteUserOnClick(data.id)} style={{ outline: 'none' }} type="delete" />
        </div>
      ),
    },
  ];

  return (
    <Table
      rowKey="id"
      className="table-component"
      size="small"
      columns={columns}
      onExpand={onExpand}
      expandedRowKeys={expandedRowKey}
      expandedRowRender={(): ReactElement | null =>
        expandedData && (
          <ExpandComponent
            expandedData={expandedData}
            saveEditedUser={saveEditedUser}
            allPermissions={allPermissions}
            editPermissions={editPermissions}
          />
        )
      }
      dataSource={users}
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: ['15', '30', '60', '100', '200'],
        defaultPageSize: 15,
      }}
      loading={tableLoading}
    />
  );
};

export default memo(UsersTable);
