import React, { ReactElement, memo } from 'react';

import humps from 'humps';

// Components
import OverviewRectangle from './overviewRectangle';

// Util Functions
import capitalize from '../../utils/capitalize';
import { Skeleton } from 'antd';
import { DrawerProps } from './analyticsPage';

interface Props {
  data: {
    topCountries: TopCountriesTypes[];
    topNetworks: TopNetworksTypes[];
    topDevices: TopDevicesTypes[];
  } | null;
  openDrawer: (info: DrawerProps) => void;
}

type TopCountriesTypes = { nwCountry: string; count: number };
type TopNetworksTypes = { nw: string; count: number };
type TopDevicesTypes = { device: string; macCount: number };

const RectangleWrapper = ({ data, openDrawer }: Props): ReactElement => {
  const normalizeData = (key: string): { mainData: { name: string; count: number } }[] | null => {
    if (data) {
      if (key === 'topCountries') {
        return data[key].map((info: TopCountriesTypes) => ({
          mainData: { name: info.nwCountry, count: info.count },
          additionalInfo: null,
        }));
      } else if (key === 'topNetworks') {
        return data[key].map((info: TopNetworksTypes) => ({
          mainData: { name: info.nw, count: info.count },
          additionalInfo: info,
        }));
      } else if (key === 'topDevices') {
        return data[key].map((info: TopDevicesTypes) => ({
          mainData: { name: info.device, count: info.macCount },
          additionalInfo: null,
        }));
      }
    }
    return null;
  };

  return (
    <>
      {data
        ? Object.keys(data).map((key: string, index: number) => {
            const tableName = capitalize(humps.decamelize(key, { separator: ' ' }));
            const tableData = normalizeData(key);
            return (
              <OverviewRectangle
                key={`overview-rectangle-item-${index}`}
                data={tableData}
                tableName={tableName}
                index={index}
                moreInfo={openDrawer}
              />
            );
          })
        : [1, 2, 3].map((rectangleData, index) => (
            <div key={`overview-rectangle-item-${index}`} className="analytics-page__overview-rectangle">
              <Skeleton active paragraph={{ rows: 3 }} />
            </div>
          ))}
    </>
  );
};

export default memo(RectangleWrapper);
