import Echo from 'laravel-echo/dist/echo';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as pusher from 'pusher-js';

import throttle from 'lodash.throttle';

import { store } from '../store';
import { getEntity } from '../actions/entityActions';

const getEntityThrottle = throttle(params => {
  if (params) store.dispatch(getEntity(params));
  else store.dispatch(getEntity({}));
}, 10000);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const startSocket = () => {
  const echo = new Echo({
    key: 'rwe',
    broadcaster: 'pusher',
    wsHost: 'rwe.smartdata.solutions',
  });

  echo.channel('rwe').listen('EntityReceivedEvent', data => {
    const params = store.getState().entity.params;

    if (data && data.key === 1 && window.location.pathname === '/') {
      getEntityThrottle(params);
    }
  });
};

export default startSocket;
