import routesPath from './routes';

export default function(path: string): string {
  if (path === routesPath.HOME) return 'homepage-view';
  else if (path === routesPath.ANALYTICS) return 'analytics-view';
  else if (path === routesPath.INSIGHTS) return 'insight-view';
  else if (path === routesPath.SETTINGS) return 'settings-view';

  return '';
}
