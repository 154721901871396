import React, { PureComponent } from 'react';

// Libraries
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { setGlobalLoading } from '../../actions/navBarActions';
import { getEntity, downloadEntityCsv, loadEntityParams } from '../../actions/entityActions';
import { updateUser } from '../../actions/userActions';

// Components
import TableComponent from '../../components/table';

// Types
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../store';
import { AppActions } from '../../types/actionsTypes';
import { APIGetEntityResponse, GetEntityParams } from '../../utils/apiTypes';
import { User } from '../../reducers/authReducer';
import humps from 'humps';

interface Props {
  getEntity: (data: GetEntityParams) => Promise<APIGetEntityResponse>;
  downloadCsv: (data: GetEntityParams | null) => Promise<APIGetEntityResponse>;
  updateUser: (id: number | null, data: { hiddenColumns: {}[] }) => Promise<[]>;
  entities: APIGetEntityResponse | null;
  hiddenColumns: string[] | null | undefined;
  setGlobalLoading: typeof setGlobalLoading;
  loadEntityParams: typeof loadEntityParams;
  user: User | null;
  downloadLoading: boolean;
}

interface State {
  tableLoading: boolean;
}

class HomePage extends PureComponent<Props, State> {
  state = {
    tableLoading: true,
  };

  componentDidMount(): void {
    this.props
      .getEntity({})
      .then(() => this.setState({ tableLoading: false }))
      .catch(() => this.setState({ tableLoading: false }));
  }

  updateHiddenColumns = (hiddenColumns: string[]): Promise<[]> =>
    this.props.updateUser(this.props.user && this.props.user.id, {
      hiddenColumns: hiddenColumns.map(item => humps.decamelize(item)),
    });

  downloadCsv = (data: {} | null): void => {
    this.props.downloadCsv(data);
  };
  render(): {} {
    const { entities, getEntity, setGlobalLoading, loadEntityParams, hiddenColumns, downloadLoading } = this.props;
    const { tableLoading } = this.state;
    return (
      <div>
        <TableComponent
          entities={entities}
          getEntity={getEntity}
          setGlobalLoading={setGlobalLoading}
          loadEntityParams={loadEntityParams}
          loading={tableLoading}
          downloadLoading={downloadLoading}
          hiddenColumns={hiddenColumns}
          updateHiddenColumns={this.updateHiddenColumns}
          downloadCsv={this.downloadCsv}
        />
      </div>
    );
  }
}

type LinkStateProps = {
  entities: APIGetEntityResponse | null;
  hiddenColumns: string[] | null | undefined;
  user: User | null;
  downloadLoading: boolean;
};

const mapStateToProps = (state: AppState): LinkStateProps => ({
  entities: state.entity.entities,
  downloadLoading: state.entity.downloadLoading,
  hiddenColumns: state.auth && state.auth.user ? state.auth.user.hiddenColumns : null,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, void, AppActions>): {} => ({
  getEntity: bindActionCreators(getEntity, dispatch),
  downloadCsv: bindActionCreators(downloadEntityCsv, dispatch),
  setGlobalLoading: bindActionCreators(setGlobalLoading, dispatch),
  loadEntityParams: bindActionCreators(loadEntityParams, dispatch),
  updateUser: bindActionCreators(updateUser, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
