import React, { ChangeEvent, ReactElement, useState, useEffect } from 'react';

// Libraries
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { bindActionCreators } from 'redux';

// Actions

// Types
import { AppActions } from '../../types/actionsTypes';
import { login } from '../../actions/authActions';
import { getUser } from '../../actions/userActions';
import history from '../../utils/history';

// Components
import Input from '../../components/input';
import Button from '../../components/button';
import Tooltip from '../../components/tooltip/tooltip';

import './loginPage.sass';

interface Props {
  login?: (state: object) => Promise<{ devToken: string }>;
  getUser?: () => Promise<unknown>;
}

const LoginPage = (props: Props): ReactElement => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [error, setError] = useState({ credentials: null, general: null });
  const [submitLoading, setSubmitLoading] = useState(false);

  const { general: generalError, credentials: credentialsError } = error;

  const onChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>): void => {
    const {
      target: { id, value },
    } = e;
    if (id === 'username') setUsername(value);
    else if (id === 'password') setPassword(value);
  };

  const isCredentialsValid = (): boolean => username.length >= 3 && password.length >= 8;

  const clearComponent = (): void => {
    setUsername('');
    setPassword('');
    setIsButtonDisabled(true);
    setError({ credentials: null, general: null });
    setSubmitLoading(false);
  };

  const handleSubmit = (e: React.SyntheticEvent<EventTarget>): void => {
    e.preventDefault();
    setSubmitLoading(true);

    props.login &&
      props
        .login({ username, password })
        .then(() => {
          props.getUser &&
            props.getUser().then(() => {
              history.push('/');
            });
        })
        .catch(err => {
          setError({ ...err.response.data.errors, general: err.response.data.message });
          setSubmitLoading(false);
        });
  };

  useEffect(() => {
    if (isCredentialsValid() && isButtonDisabled) setIsButtonDisabled(false);
    else if (!isCredentialsValid() && !isButtonDisabled) setIsButtonDisabled(true);
  }, [username, password]);

  useEffect(() => {
    return (): void => {
      setSubmitLoading(false);

      clearComponent();
    };
  }, []);

  return (
    <div className="login-page">
      <div className="login-page__wrapper">
        <h2>LOGIN</h2>
        {generalError && (
          <div className="login-page__general-error">
            {generalError && <div>{generalError}</div>}
            {credentialsError && <div>{credentialsError}</div>}
          </div>
        )}
        <form onSubmit={handleSubmit} autoComplete="off">
          <Input placeholder="Username" value={username} id="username" onChange={onChange} />
          <Input placeholder="Password" value={password} id="password" type="password" onChange={onChange} />
          <Tooltip
            message="Password length should be greater or equal to 8 and username greater or equal to 3"
            id="button-login-submit"
            showTooltip={!isCredentialsValid()}
          >
            <Button disabled={isButtonDisabled} type="submit" loading={submitLoading}>
              Login
            </Button>
          </Tooltip>
        </form>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<unknown, unknown, AppActions>): unknown => ({
  login: bindActionCreators(login, dispatch),
  getUser: bindActionCreators(getUser, dispatch),
});

export default connect(null, mapDispatchToProps)(LoginPage);
