import React, { ReactElement, memo } from 'react';
import SelectLib, { Props } from 'react-select';
// import makeAnimated from 'react-select/animated';

import './select.sass';

// type Props = {
//   isMulti?: boolean;
//   className?: string;
//   options?: Array<{ value: string; id: string | number }>;
//   onChange?: (value: any) => void;
//   placeholder?: string;
//   value?: {} | Array<{}>;
// };

// const animatedComponents = makeAnimated();

const Select = (props: Props): ReactElement => {
  const { isMulti = false, className = '' } = props;
  return (
    <SelectLib
      className={`select${className ? ` ${className}` : ''}`}
      // components={animatedComponents}
      closeMenuOnSelect={!isMulti}
      // getOptionLabel={(option): string => option.value}
      // getOptionValue={(option): string => option.id}
      // isMulti={isMulti}
      // options={options}
      {...props}
    />
  );
};

export default memo(Select);
