import React, { ChangeEvent, ReactElement, useState, useCallback } from 'react';
import Input from '../../../components/input/input';
import { Switch, Icon } from 'antd';
import Button from '../../../components/button/button';
import { Credentials } from './adminSettings';
import { AdminUserObject } from '../../../reducers/adminReducer';
import { addSerialNumbersToUser, deleteSerialNumbersToUser } from '../../../actions/adminActions';
import { store } from '../../../store';

interface Props {
  expandedData: AdminUserObject | null;
  saveEditedUser?: (id: string | number, data: Credentials) => Promise<AdminUserObject>;
  allPermissions: {
    id: number;
    name: string;
  }[];
  editPermissions?: (userId: number, permissions: string[] | null[]) => Promise<AdminUserObject>;
}

const ExpandComponent = ({
  expandedData,
  saveEditedUser,
  allPermissions,
  editPermissions,
}: Props): ReactElement | null => {
  const [editedData, setEditedData] = useState<{ credentials: Credentials }>({
    credentials: {
      username: expandedData ? expandedData.username : '',
      password: '',
      passwordConfirmation: '',
    },
  });
  const [editUserLoading, setEditUserLoading] = useState(false);
  const [editSerialNumberLoading, setEditSerialNumberLoading] = useState(false);
  const [serialNumber, setSerialNumber] = useState('');

  const editUserInputOnChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>): void =>
    setEditedData({
      ...editedData,
      credentials: { ...editedData.credentials, [e.target.name]: e.target.value },
    });

  const permissionsOnChange = (state: boolean, permission: { id: number; name: string }): void => {
    if (expandedData) {
      const newPermissions = [...expandedData.permissions];

      if (state) {
        newPermissions.push(permission);
      } else {
        const index = newPermissions.findIndex(per => per.id === permission.id);
        newPermissions.splice(index, 1);
      }

      const formattedPermissions = newPermissions.map(per => per.name);
      editPermissions && editPermissions(expandedData.id, formattedPermissions.length ? formattedPermissions : [null]);
    }
  };

  const saveUserOnClick = useCallback((): void => {
    if (expandedData) {
      if (editedData.credentials.password && editedData.credentials.passwordConfirmation) {
        setEditUserLoading(true);
        saveEditedUser &&
          saveEditedUser(expandedData.id, editedData.credentials)
            .then(() => setEditUserLoading(false))
            .catch(() => setEditUserLoading(false));
      } else if (editedData.credentials.username !== expandedData.username) {
        setEditUserLoading(true);
        saveEditedUser &&
          saveEditedUser(expandedData.id, { username: editedData.credentials.username })
            .then(() => setEditUserLoading(false))
            .catch(() => setEditUserLoading(false));
      }
    }
  }, [editedData]);

  const onSerialNumberChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    setSerialNumber(e.target.value);
  };

  const addSerialNumber = (userId: number): void => {
    setEditSerialNumberLoading(true);
    store.dispatch(addSerialNumbersToUser(userId, serialNumber)).then(() => {
      setSerialNumber('');
      setEditSerialNumberLoading(false);
    });
  };

  const deleteSerialNumber = (id: number): void => {
    store.dispatch(deleteSerialNumbersToUser(id));
  };

  const buildPermissions = useCallback((): ReactElement[] | null => {
    if (allPermissions.length && expandedData) {
      return allPermissions.map(permission => {
        const name = permission.name.split('-').join(' ');
        return (
          <div key={`permission-element-${permission.id}`}>
            <span>{name.charAt(0).toUpperCase() + name.slice(1)}</span>
            <Switch
              checked={expandedData.permissions.map(per => per.name).includes(permission.name)}
              onChange={(state): void => permissionsOnChange(state, permission)}
            />
          </div>
        );
      });
    }

    return null;
  }, [expandedData && expandedData.permissions.length]);

  return (
    expandedData && (
      <div className="settings-page__expanded-wrapper">
        <div className="settings-page__user-info-wrapper">
          <div className="settings-page__user-info-wrapper_inputs">
            <Input
              name="username"
              value={editedData.credentials ? editedData.credentials.username : ''}
              onChange={editUserInputOnChange}
            />
            <Input name="password" type="password" placeholder="password" onChange={editUserInputOnChange} />
            <Input
              name="passwordConfirmation"
              type="password"
              placeholder="repeat password"
              onChange={editUserInputOnChange}
            />
            <Button loading={editUserLoading} onClick={saveUserOnClick}>
              Save
            </Button>
          </div>
          <div className="settings-page__pop-up-user-creation-toggles">
            <div className="settings-page__pop-up-user-creation-toggles_title">Permissions</div>
            {buildPermissions()}
          </div>
          <div className="settings-page__pop-up-user-creation-toggles">
            <div className="settings-page__pop-up-user-creation-toggles_title">Serial Numbers</div>
            {expandedData.serialNumbers &&
              expandedData.serialNumbers.map(number => (
                <div key={`serial-number-${number.id}`}>
                  <span>{number.number}</span>
                  <Icon
                    type="delete"
                    style={{ fontSize: '16px', color: '#ff0000', marginLeft: 10 }}
                    theme="outlined"
                    onClick={(): void => deleteSerialNumber(number.id)}
                  />
                </div>
              ))}
            <Input
              placeholder="Insert serial number here..."
              value={serialNumber}
              id="serialNumber"
              onChange={onSerialNumberChange}
            />
            <Button
              disabled={!serialNumber}
              loading={editSerialNumberLoading}
              onClick={(): void => addSerialNumber(expandedData.id)}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    )
  );
};

export default ExpandComponent;
