import React, { ErrorInfo, ReactElement, ReactNode } from 'react';

import { Result, Typography } from 'antd';

import Button from '../button/button';

const { Paragraph, Text } = Typography;

interface Props {
  children: ReactNode;
}

interface State {
  error: unknown | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends React.Component<Props, State> {
  state = { error: null, errorInfo: null };

  componentDidCatch(error: unknown, errorInfo: ErrorInfo): void {
    this.setState({ error, errorInfo });
  }

  restartPage = (): void => location.reload();

  render(): ReactElement | ReactNode {
    const { error, errorInfo } = this.state;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const errorBody = error ? error.toString() : '';
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const errorInfoBody = errorInfo ? errorInfo.componentStack : '';
    return this.state.error ? (
      <Result
        status="error"
        title={errorBody}
        subTitle="Please contact the Admin and give him the following information below"
        extra={
          <Button styleType="primary" onClick={this.restartPage}>
            Restart the Page
          </Button>
        }
      >
        <Paragraph>
          <Text
            strong
            style={{
              fontSize: 16,
              whiteSpace: 'pre-wrap',
            }}
          >
            {errorInfoBody}
          </Text>
        </Paragraph>
      </Result>
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
