import React, { ReactElement, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { Icon, Table } from 'antd';

import Select from '../../components/select';
import Button from '../../components/button';
import Input from '../../components/input';

import { AppActions } from '../../types/actionsTypes';
import { DevicesElemTypes } from '../../reducers/devicesReducer';
import { getDevicesList, getAvailableTimezones, updateDeviceTimezone } from '../../actions/devicesActions';
import { AppState } from '../../store';
import './devicesPage.sass';

type FilterDropdownType = {
  setSelectedKeys: (selectedKeys: string[]) => void;
  selectedKeys: string[];
  confirm: () => void;
  clearFilters: () => void;
};

type DevicesTypes = {
  getDevicesList: () => Promise<unknown>;
  getAvailableTimezones: () => Promise<string[]>;
  updateDeviceTimezone: (data: { unitSerial: string; value: string }) => Promise<unknown>;
  devices: DevicesElemTypes[];
};

const DevicesPage = (props: DevicesTypes): ReactElement => {
  const [devicesList, setDevicesList] = useState<any>([]);
  const [timezonesList, setTimezonesList] = useState<string[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editModeRow, setEditModeRow] = useState<string>('');
  const [selectedTimezone, setSelectedTimezone] = useState<string>('');
  const [loadingTable, setLoadingTable] = useState<boolean>(false);

  const runEditMode = (unitSerial: string, timezone: string) => {
    setEditMode(true);
    setEditModeRow(unitSerial);
    if (timezone) {
      setSelectedTimezone(timezone);
    } else {
      setSelectedTimezone('');
    }
  };

  const cancelEditMode = () => {
    setEditMode(false);
    setEditModeRow('');
  };

  const saveChanges = (unitSerial: string) => {
    const data = { unitSerial: unitSerial, value: selectedTimezone };
    props.updateDeviceTimezone(data).then(() => {
      setEditMode(false);
      setEditModeRow('');
    });
  };

  const onSelectTimezone = (data: any) => {
    setSelectedTimezone(data.value);
  };

  const getColumnSearchProps = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownType) => {
      return (
        <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
          <Input
            placeholder="Search Hash"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button onClick={() => confirm()} size="small" style={{ width: 90 }}>
              Search
            </Button>
            <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: (filtered: boolean) => <Icon onClick={() => cancelEditMode()} type="search" />,
    onFilter: (value: any, record: any) =>
      record.unitSerial
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  });

  const columns = [
    {
      title: 'Hash',
      dataIndex: 'unitSerial',
      key: 'unitSerial',
      ...getColumnSearchProps(),
    },
    {
      title: 'Timezone',
      dataIndex: 'timezone',
      key: 'timezone',
      render: (record: { value: string }, data: { unitSerial: string }): ReactElement => {
        if (loadingTable) {
          return <span />;
        }

        if (editMode && editModeRow === data.unitSerial) {
          return (
            <Select
              className="timezone-select"
              isSearchable
              defaultValue={record.value ? { label: record.value, value: record.value } : null}
              onChange={data => onSelectTimezone(data)}
              options={timezonesList.map((timezone: string) => ({ label: timezone, value: timezone }))}
            />
          );
        } else {
          return <span>{record.value ? record.value : 'Not set yet'}</span>;
        }
      },
      sorter: (a: any, b: any) => {
        const valueA = a.timezone && a.timezone.value ? a.timezone.value.toLowerCase() : '';
        const valueB = b.timezone && b.timezone.value ? b.timezone.value.toLowerCase() : '';

        if (valueA < valueB) {
          return -1;
        }
        if (valueA > valueB) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '80px',
      render: (_: any, data: any): ReactElement => {
        if (loadingTable) {
          return <span />;
        }

        if (editModeRow === data.unitSerial) {
          return (
            <div className="table-component__actions-wrapper">
              <Icon style={{ outline: 'none' }} onClick={() => cancelEditMode()} type="close" />
              <Icon style={{ outline: 'none' }} onClick={() => saveChanges(data.unitSerial)} type="save" />
            </div>
          );
        } else {
          return (
            <div className="table-component__actions-wrapper">
              <Icon
                style={{ outline: 'none' }}
                onClick={() => runEditMode(data.unitSerial, data.timezone.value)}
                type="edit"
              />
            </div>
          );
        }
      },
    },
  ];

  useEffect(() => {
    if (props.devices.length) {
      setDevicesList(props.devices);
    }
  }, [props.devices]);

  useEffect(() => {
    setLoadingTable(true);
    props
      .getDevicesList()
      .then(res => {
        setDevicesList(res);
      })
      .finally(() => {
        setLoadingTable(false);
      });
    props.getAvailableTimezones().then((res: string[]) => {
      setTimezonesList(res);
    });
  }, []);

  return (
    <div className="devices-page">
      <Table
        className="table-component"
        rowKey="unitSerial"
        columns={columns}
        dataSource={devicesList}
        size="middle"
        loading={loadingTable}
        pagination={false}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  devices: state.devices.devices,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, void, AppActions>): {} => ({
  getDevicesList: bindActionCreators(getDevicesList, dispatch),
  getAvailableTimezones: bindActionCreators(getAvailableTimezones, dispatch),
  updateDeviceTimezone: bindActionCreators(updateDeviceTimezone, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DevicesPage);
