import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import history from './utils/history';
import { store } from './store';

import Routes from './routes';
import ErrorBoundary from './components/errorBoundary';

import initApiConfig from './utils/apiConfig';
import startSocket from './utils/socket';

import './index.sass';

import * as serviceWorker from './serviceWorker';

initApiConfig();
startSocket();

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <Router history={history}>
        <Routes />
      </Router>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
