import React, { ReactElement, ReactNode, useEffect } from 'react';
import NavBarTop from './components/navBarTop';

type Props = {
  children?: ReactNode;
  darkMode: boolean;
};

const AuthenticatedApp = (props: Props): ReactElement => {
  useEffect(() => {
    const body = document.body;
    if (props.darkMode) body.classList.add('dark-mode-app');
    else body.classList.remove('dark-mode-app');
  }, [props.darkMode]);
  return (
    <div className="app">
      <NavBarTop />
      <>{props.children}</>
    </div>
  );
};

export default AuthenticatedApp;
