import React, { PureComponent, memo } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import { Icon } from 'antd';

import HistoryTypes from '../../types/historyTypes';
import { AppActions } from '../../types/actionsTypes';
import { apiLogout } from '../../actions/authActions';
import { AppState } from '../../store';

import routesPath, { HOME, ANALYTICS, SETTINGS, INSIGHTS, DEVICES } from '../../constants/routes';

import './navBarLeft.sass';
import { User } from '../../reducers/authReducer';
import pathPermissionConverter from '../../constants/pathPermissionConverter';

type Props = {
  expanded: boolean;
  user: User | null;
  logout?: () => AppActions;
};

type ExtendedProps = Props & HistoryTypes;

class NavBarLeft extends PureComponent<ExtendedProps> {
  logout = (): void => {
    this.props.logout && this.props.logout();
  };

  isPageEnabled = (path: string): boolean => {
    const { user } = this.props;
    const convertedPath = pathPermissionConverter(path);

    if (user) {
      if (user.permissions.map(per => per.name).includes(convertedPath)) return true;
    }

    return false;
  };

  render(): {} {
    const { expanded, location } = this.props;
    return (
      <div className={`nav-bar-left ${expanded && '--expand'}`}>
        <div className="nav-bar-left__wrapper">
          {this.isPageEnabled(routesPath.HOME) && (
            <Link
              to={routesPath.HOME}
              className={`nav-bar-left__item ${location.pathname === routesPath.HOME && '--active'}`}
            >
              <span className="nav-bar-left__item_bar" />
              <Icon type="import" />
              <div className="nav-bar-text">{HOME}</div>
            </Link>
          )}
          {this.isPageEnabled(routesPath.ANALYTICS) && (
            <Link
              to={routesPath.ANALYTICS}
              className={`nav-bar-left__item ${location.pathname === routesPath.ANALYTICS && '--active'}`}
            >
              <span className="nav-bar-left__item_bar" />
              <Icon type="dot-chart" />
              <div className="nav-bar-text">{ANALYTICS}</div>
            </Link>
          )}
          <Link
            to={routesPath.SETTINGS}
            className={`nav-bar-left__item ${location.pathname === routesPath.SETTINGS && '--active'}`}
          >
            <span className="nav-bar-left__item_bar" />
            <Icon type="setting" />
            <div className="nav-bar-text">{SETTINGS}</div>
          </Link>
          {this.isPageEnabled(routesPath.INSIGHTS) && (
            <Link
              to={routesPath.INSIGHTS}
              className={`nav-bar-left__item ${location.pathname === routesPath.INSIGHTS && '--active'}`}
            >
              <span className="nav-bar-left__item_bar" />
              <Icon type="radar-chart" />
              <div className="nav-bar-text">{INSIGHTS}</div>
            </Link>
          )}
          <Link
            to={routesPath.DEVICES}
            className={`nav-bar-left__item ${location.pathname === routesPath.DEVICES && '--active'}`}
          >
            <span className="nav-bar-left__item_bar" />
            <Icon type="database" />
            <div className="nav-bar-text">{DEVICES}</div>
          </Link>
          <div className="nav-bar-left__item" onClick={this.logout}>
            <span className="nav-bar-left__item_bar" />
            <Icon className="nav-bar-left__item_power-off" type="poweroff" />
            <div className="nav-bar-text nav-bar-text--red">Logout</div>
          </div>
        </div>
      </div>
    );
  }
}

interface LinkStateProps {
  expanded: boolean;
  user: User | null;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  expanded: state.navBar.expanded,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<unknown, unknown, AppActions>): unknown => ({
  logout: bindActionCreators(apiLogout, dispatch),
});

export default memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBarLeft)));
