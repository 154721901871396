const UserRoles = {
  admin: 'admin',
  user: 'user',
};

const userRoles = {
  admins: [String(UserRoles.admin)],
  user: [String(UserRoles.user)],
  all: [UserRoles.admin, UserRoles.user],
};

export default userRoles;
