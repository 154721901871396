import React, { ReactElement, ChangeEvent, FocusEvent, memo, DetailedHTMLProps } from 'react';

import './input.sass';

type Props = {
  value?: string;
  defaultValue?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: FocusEvent) => void;
  onBlur?: (e: FocusEvent) => void;
  onKeyPress?: (e: DetailedHTMLProps<{}, {}>) => void;
  label?: string;
  error?: { text: string } | null;
  width?: number;
  disabled?: boolean;
  placeholder?: string;
  style?: {};
  textarea?: boolean;
  height?: number;
  name?: string;
  id?: string;
  type?: string;
};

const Input = ({
  value,
  defaultValue,
  onChange,
  onFocus,
  onBlur,
  label,
  error,
  width,
  placeholder,
  disabled = false,
  style,
  textarea = false,
  height,
  name,
  id,
  onKeyPress,
  type,
}: Props): ReactElement => {
  return (
    <div className="input-wrapper" style={{ width, ...style }}>
      {label && <div className="input-label">{label}</div>}
      {!textarea ? (
        <input
          className={`input${error ? ' input--error' : ''}`}
          type={type || 'text'}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          disabled={disabled}
          name={name}
          id={id}
          onKeyPress={onKeyPress}
          autoComplete="off"
        />
      ) : (
        <textarea
          className={`textarea${error ? ' textarea--error' : ''}`}
          style={{ height }}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          disabled={disabled}
          rows={4}
          name={name}
          id={id}
          onKeyPress={onKeyPress}
        />
      )}
      {error && <div className="input-error-message">{error.text}</div>}
    </div>
  );
};

export default memo<Props>(Input);
