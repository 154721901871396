import { TOGGLE_BAR, SET_GLOBAL_LOADING } from '../constants/actionTypes';
import { AppActions } from '../types/actionsTypes';

export const toggleBar = (): AppActions => ({
  type: TOGGLE_BAR,
});

export const setGlobalLoading = (loadingState: boolean): AppActions => ({
  type: SET_GLOBAL_LOADING,
  payload: loadingState,
});
