import React, { ReactElement, useCallback, useEffect, useState } from 'react';

// Main Libraries
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

// Libraries
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';

// Actions
import {
  getGeneralAnalytics,
  getChartAnalytics,
  getLineAnalytics,
  GetGeneralAnalyticsOptionTypes,
  GetChartAnalyticsOptionTypes,
  GetLineAnalyticsOptionTypes,
} from '../../actions/analyticsActions';

// Components
import Select from '../../components/select';
import DoughnutCharts from './chartsDoughnut';
import LineChart from './chartLine';
import SquareWrapper from './squareWrapper';
import RectangleWrapper from './rectangleWrapper';
import AnalyticDrawer from './drawer';

// Types
import {
  AnalyticChartsTypes,
  AnalyticLineChartsTypes,
  APIGetAnalyticsResponse,
  APIGetGetGeneralAnalyticsResponse,
} from '../../utils/apiTypes';
import { AppState } from '../../store';
import { AppActions } from '../../types/actionsTypes';

import './analyticsPage.sass';
// import { ValueType } from 'react-select';
import { RangePickerValue } from 'antd/lib/date-picker/interface';

const selectOptions = [
  { value: '1', label: 'Last day' },
  { value: '7', label: 'Last week' },
  { value: '30', label: 'Last 30 days' },
  { value: '90', label: 'Last 3 month' },
  { value: '180', label: 'Last 6 month' },
  { value: '365', label: 'Last year' },
  { value: '0', label: 'All time' },
];

// Interfaces
interface Props {
  getAnalytics: (options?: { days?: number }) => Promise<APIGetAnalyticsResponse>;
  getGeneralAnalytics: (
    options: GetGeneralAnalyticsOptionTypes,
  ) => Promise<{ data: APIGetGetGeneralAnalyticsResponse }>;
  getChartAnalytics: (options: GetChartAnalyticsOptionTypes) => Promise<{ data: APIGetGetGeneralAnalyticsResponse }>;
  getLineAnalytics: (options: GetLineAnalyticsOptionTypes) => Promise<{ data: APIGetGetGeneralAnalyticsResponse }>;
  generalAnalytics: APIGetGetGeneralAnalyticsResponse;
  chartAnalytics: AnalyticChartsTypes | null;
  lineAnalytics: AnalyticLineChartsTypes | null;
  darkMode: boolean;
}

interface SelectedProps {
  value: string;
  label: string;
}

export interface DrawerProps {
  name: string;
  infoType: string;
  data:
    | {
        mainData: {} | [] | null;
        additionalInfo?: {} | [];
      }
    | {}[]
    | null;
}

interface SelectTypes {
  value: SelectedProps | null;
  isLoading: boolean;
  isDisabled: boolean;
}

interface RangeSelectTypes {
  momentValue: [Moment, Moment] | undefined;
  stringValue?: string[];
  isDisabled: boolean;
}

const AnalyticsPage = ({
  getGeneralAnalytics,
  generalAnalytics,
  darkMode,
  getChartAnalytics,
  chartAnalytics,
  getLineAnalytics,
  lineAnalytics,
}: Props): ReactElement => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const defaultSelectedValue = { value: '1', label: 'Last day' };
  const [generalSelect, setGeneralSelect] = useState<SelectTypes>({
    value: defaultSelectedValue,
    isLoading: false,
    isDisabled: false,
  });
  const [generalRangeSelect, setGeneralRangeSelect] = useState<RangeSelectTypes>({
    momentValue: undefined,
    isDisabled: false,
  });
  const [chartsSelected, setChartsSelected] = useState<SelectTypes>({
    value: defaultSelectedValue,
    isLoading: false,
    isDisabled: false,
  });
  const [chartsRangeSelect, setChartsRangeSelect] = useState<RangeSelectTypes>({
    momentValue: undefined,
    isDisabled: false,
  });
  const [drawerInfo, setDrawerInfo] = useState({
    name: '',
    infoType: '',
    data: {},
  });

  useEffect(() => {
    getGeneralAnalytics({ days: 1, allData: 0 });
    getChartAnalytics({ days: 1 });
    getLineAnalytics({ days: 1, startTime: '00:00', endTime: '23:59' });
  }, []);

  const openDrawer = (info: DrawerProps): void => {
    const topName = (): string => {
      const strArr = info.name.toLowerCase().split(' ');
      return strArr[0] + (strArr[1].charAt(0).toUpperCase() + strArr[1].slice(1));
    };

    const doughnutIndex = info.name.slice(-1);
    if (generalRangeSelect.momentValue && info.infoType === 'rectangle') {
      getGeneralAnalytics({
        start: moment(generalRangeSelect.momentValue[0], 'YYYY-MM-DD').format('DD-MM-YYYY'),
        end: moment(generalRangeSelect.momentValue[1], 'YYYY-MM-DD').format('DD-MM-YYYY'),
        allData: 1,
      }).then(res => {
        setDrawerVisible(true);
        setDrawerInfo({
          ...info,
          data: {
            additionalInfo: res.data.tops && res.data.tops[topName() as 'topCountries' | 'topDevices' | 'topNetworks'],
          },
        });
      });
    }
    if (generalSelect.value && info.infoType === 'rectangle') {
      getGeneralAnalytics({ days: parseInt(generalSelect.value.value, 10), allData: 1 }).then(res => {
        setDrawerVisible(true);
        setDrawerInfo({
          ...info,
          data: {
            additionalInfo: res.data.tops && res.data.tops[topName() as 'topCountries' | 'topDevices' | 'topNetworks'],
          },
        });
      });
    }
    if (chartsSelected.value && info.infoType === 'doughnutChart') {
      getChartAnalytics({ days: parseInt(chartsSelected.value.value, 10) }).then(res => {
        setDrawerVisible(true);
        setDrawerInfo({
          ...info,
          data: {
            additionalInfo: res.data && Object.values(res.data)[(doughnutIndex as unknown) as number].data,
          },
        });
      });
    }
    if (info.infoType === 'lineChart') {
      setDrawerVisible(true);
      setDrawerInfo({
        ...info,
        data: {
          additionalInfo: lineAnalytics,
        },
      });
    }
  };

  const closeDrawer = useCallback((): void => setDrawerVisible(false), []);

  const overviewSelectOnChange = useCallback((e): void => {
    setGeneralSelect({ ...generalSelect, isLoading: true, value: e });
    setGeneralRangeSelect({ ...generalRangeSelect, momentValue: undefined });
    getGeneralAnalytics({ days: parseInt(e.value as string, 10), allData: 0 }).then(() =>
      setGeneralSelect({ ...generalSelect, isLoading: false, value: e }),
    );
  }, []);

  const overviewRangeOnChange = useCallback((e: RangePickerValue, dates: string[]): void => {
    if (dates[0] !== '' && dates[1] !== '') {
      setGeneralSelect({ ...generalSelect, value: null });
      getGeneralAnalytics({
        start: moment(dates[0], 'YYYY-MM-DD').format('DD-MM-YYYY'),
        end: moment(dates[1], 'YYYY-MM-DD').format('DD-MM-YYYY'),
        allData: 0,
      }).then(() =>
        setGeneralRangeSelect({ ...generalRangeSelect, momentValue: e as [Moment, Moment], stringValue: dates }),
      );
    } else {
      setGeneralRangeSelect({ ...generalRangeSelect, momentValue: undefined });
      getGeneralAnalytics({ days: 1, allData: 0 }).then(() =>
        setGeneralSelect({ ...generalSelect, isLoading: false, value: { value: '1', label: 'Last day' } }),
      );
    }
  }, []);

  const chartsSelectOnChange = useCallback((e): void => {
    setChartsSelected({ ...chartsSelected, isLoading: true, value: e });
    setChartsRangeSelect({ ...generalRangeSelect, momentValue: undefined });
    getChartAnalytics({ days: parseInt(e.value as string, 10) }).then(() =>
      setChartsSelected({ ...chartsSelected, isLoading: false, value: e }),
    );
  }, []);

  const chartsRangeOnChange = useCallback((e: RangePickerValue, dates: string[]): void => {
    if (dates[0] !== '' && dates[1] !== '') {
      setChartsSelected({ ...chartsSelected, value: null });
      getChartAnalytics({
        start: moment(dates[0], 'YYYY-MM-DD').format('DD-MM-YYYY'),
        end: moment(dates[1], 'YYYY-MM-DD').format('DD-MM-YYYY'),
      }).then(() =>
        setChartsRangeSelect({ ...chartsRangeSelect, momentValue: e as [Moment, Moment], stringValue: dates }),
      );
    } else {
      setChartsRangeSelect({ ...chartsRangeSelect, momentValue: undefined });
      getChartAnalytics({ days: 1 }).then(() =>
        setChartsSelected({ ...chartsSelected, isLoading: false, value: { value: '1', label: 'Last day' } }),
      );
    }
  }, []);
  return (
    <div className="analytics-page">
      <AnalyticDrawer onClose={closeDrawer} visible={drawerVisible} data={drawerInfo} />
      <div className="analytics-page__header-row">
        <h3>Analytics Overview</h3>
        <div className="analytics-page__header-row_selectors-wrapper">
          <DatePicker.RangePicker
            dropdownClassName="rangePicker-dark-mode"
            value={generalRangeSelect.momentValue}
            onChange={overviewRangeOnChange}
            style={{ marginBottom: 10 }}
          />
          <Select
            value={generalSelect.value}
            onChange={overviewSelectOnChange}
            placeholder="Filter by days"
            options={selectOptions}
            isLoading={generalSelect.isLoading}
            isDisabled={generalSelect.isDisabled}
          />
        </div>
      </div>
      <div className="analytics-page__overview-wrapper">
        <div className="analytics-page__overview-square-wrapper">
          <SquareWrapper data={generalAnalytics} />
        </div>
        <div className="analytics-page__overview-rectangle-wrapper">
          <RectangleWrapper data={generalAnalytics ? generalAnalytics.tops : null} openDrawer={openDrawer} />
        </div>
      </div>
      <div className="analytics-page__header-row">
        <h3>Charts Overview</h3>
        <div className="analytics-page__header-row_selectors-wrapper">
          <DatePicker.RangePicker
            dropdownClassName="rangePicker-dark-mode"
            value={chartsRangeSelect.momentValue}
            onChange={chartsRangeOnChange}
            style={{ marginBottom: 10 }}
          />
          <Select
            value={chartsSelected.value}
            onChange={chartsSelectOnChange}
            placeholder="Filter by days"
            options={selectOptions}
            isLoading={chartsSelected.isLoading}
            isDisabled={chartsSelected.isDisabled}
          />
        </div>
      </div>
      <DoughnutCharts data={chartAnalytics} moreInfo={openDrawer} darkMode={darkMode} />
      <LineChart data={lineAnalytics} moreInfo={openDrawer} selectOptions={selectOptions} darkMode={darkMode} />
    </div>
  );
};

type LinkStateProps = {
  generalAnalytics: APIGetGetGeneralAnalyticsResponse;
  chartAnalytics: AnalyticChartsTypes | null;
  lineAnalytics: AnalyticLineChartsTypes | null;
  darkMode: boolean;
};

const mapStateToProps = (state: AppState): LinkStateProps => ({
  generalAnalytics: state.analytics.general,
  chartAnalytics: state.analytics.charts,
  lineAnalytics: state.analytics.line,
  darkMode: state.app.darkMode,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, void, AppActions>): {} => ({
  getGeneralAnalytics: bindActionCreators(getGeneralAnalytics, dispatch),
  getChartAnalytics: bindActionCreators(getChartAnalytics, dispatch),
  getLineAnalytics: bindActionCreators(getLineAnalytics, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsPage);
