import React, { memo, ReactElement } from 'react';
import { Drawer, Table } from 'antd';
// import { DrawerProps } from './analyticsPage';

interface Props {
  data: { name: string; infoType: string; data: {} };
  onClose: () => void;
  visible: boolean;
}

const AnalyticDrawer = ({ data, onClose, visible }: Props): ReactElement | null => {
  const name = (): string => {
    switch (data.name) {
      case 'Top devices':
        return 'device';
      case 'Top networks':
        return 'nw';
      case 'Chart with index 1':
        return 'nw';
      case 'Chart with index 2':
        return 'device';
      case 'Chart with index 3':
        return 'hotSpot';
      case 'Line Chart':
        return 'day';
      default:
        return 'nwCountry';
    }
  };
  const columns = [
    {
      title: data.infoType === 'lineChart' ? 'Day' : 'Name',
      dataIndex: name(),
      key: name(),
    },
    {
      title: 'Count',
      dataIndex: data.name === 'Top devices' ? 'macCount' : 'count',
      key: data.name === 'Top devices' ? 'macCount' : 'count',
    },
  ];
  const networkColumns = [
    {
      title: 'Name',
      dataIndex: 'nw',
      key: 'nw',
    },
    {
      title: 'ID',
      dataIndex: 'nwId',
      key: 'nwId',
    },
    {
      title: 'Brand',
      dataIndex: 'nwBrand',
      key: 'nwBrand',
    },
    {
      title: 'Country',
      dataIndex: 'nwCountry',
      key: 'nwCountry',
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
    },
  ];
  return data ? (
    <Drawer width={800} placement="right" closable={false} onClose={onClose} visible={visible}>
      <h2>{data.name} Info</h2>
      <div className="analytics-page__info-wrapper">
        <Table
          className="table-component"
          rowKey={(record, index): string => `id-${index}`}
          columns={data && data.name === 'Top networks' ? networkColumns : columns}
          dataSource={data && Object.values(data.data as [])[0]}
          scroll={{ x: true }}
          size="small"
          pagination={{ pageSize: 20 }}
        />
      </div>
    </Drawer>
  ) : null;
};

export default memo(AnalyticDrawer);
