import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

// Reducers
import appReducer from './reducers/appReducer';
import navBarReducer from './reducers/navBarReducer';
import authReducer from './reducers/authReducer';
import entityReducer from './reducers/entityReducer';
import analyticsReducer from './reducers/analyticsReducer';
import adminReducer from './reducers/adminReducer';
import devicesReducer from './reducers/devicesReducer';

// Types
import { AppActions } from './types/actionsTypes';

export const rootReducer = combineReducers({
  app: appReducer,
  navBar: navBarReducer,
  auth: authReducer,
  entity: entityReducer,
  analytics: analyticsReducer,
  admin: adminReducer,
  devices: devicesReducer,
  timezones: devicesReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>)),
);
