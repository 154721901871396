import { GET_DEVICES_LIST, GET_AVAILABLE_TIMEZONES, UPDATE_DEVICE_TIMEZONE } from '../constants/actionTypes';
import { DevicesActionTypes } from '../types/devicesActionTypes';

export interface DevicesPayloadTypes {
  unitSerial: string;
  key: string;
  value: string;
}

export interface DevicesElemTypes {
  unitSerial: string;
  timezone: {
    unitSerial: string;
    key: string;
    value: string;
  };
}
export interface DevicesTypes {
  devices: any;
  timezones: any;
}

const initialState: DevicesTypes = {
  devices: [],
  timezones: [],
};

export default function devicesReducer(state = initialState, action: DevicesActionTypes): DevicesTypes {
  switch (action.type) {
    case GET_DEVICES_LIST:
      return {
        ...state,
        devices: action.payload,
      };
    case GET_AVAILABLE_TIMEZONES:
      return {
        ...state,
        timezones: action.payload,
      };
    case UPDATE_DEVICE_TIMEZONE: {
      const updatedDevices = Object.values(state.devices).map((device: any) => {
        if (device.unitSerial === action.payload.unitSerial) {
          return {
            ...device,
            timezone: {
              unitSerial: device.unitSerial,
              key: action.payload.key,
              value: action.payload.value,
            },
          };
        }
        return device;
      });
      return {
        ...state,
        devices: updatedDevices,
      };
    }
    default:
      return state;
  }
}
