import React, { FC, ReactElement } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Route, Redirect } from 'react-router-dom';
import { NonAuthRoutes } from '../../constants/routes';
import { AppState } from '../../store';
import { User } from '../../reducers/authReducer';
import pathPermissionConverter from '../../constants/pathPermissionConverter';

interface Props {
  Component: FC<RouteComponentProps>;
  path: string;
  exact?: boolean;
  requiredRoles: string[];
  user: User | null;
}

const AuthRoute = ({ user, Component, path, exact = false, requiredRoles }: Props): ReactElement => {
  const isLogged = !!sessionStorage.getItem('token');
  const userRole = user && user.role ? user.role : '';
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const userHasRequiredRole = requiredRoles.includes(userRole);

  const userHasAccess = (): boolean => {
    if (user) {
      const convertedPath = pathPermissionConverter(path);
      return user.permissions.map(per => per.name).includes(convertedPath) || user.role === 'admin';
    }

    return false;
  };

  const message = userHasRequiredRole ? 'Please log in to view this page' : 'You cant be here!';

  return (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps): ReactElement =>
        isLogged && userHasRequiredRole && userHasAccess() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              // pathname: userHasRequiredRole ? NonAuthRoutes.login : NonAuthRoutes.unauthorized,
              pathname: userHasRequiredRole ? NonAuthRoutes.unauthorized : '/unauthorized',
              state: { message, requestPath: path },
            }}
          />
        )
      }
    />
  );
};

type LinkStateProps = {
  user: User | null;
};

const mapStateToProps = (state: AppState): LinkStateProps => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(AuthRoute);
