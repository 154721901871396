import { TOGGLE_DARK_MODE } from '../constants/actionTypes';
import { AppActions } from '../types/actionsTypes';

export const toggleDarkMode = (state: boolean): AppActions => {
  window.localStorage.setItem('darkMode', `${state}`);
  return {
    type: TOGGLE_DARK_MODE,
    payload: state,
  };
};
