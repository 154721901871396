import axios, { AxiosResponse } from 'axios';
import humps from 'humps';
import dispatchNotification from '../components/notification';
import { store } from '../store';
import { logout } from '../actions/authActions';
import parseErrorForNotification from './parseErrorForNotification';

export const URL = 'https://rwe.smartdata.solutions/api';
axios.defaults.baseURL = URL;
axios.defaults.withCredentials = true;

// Bearer implementation
if (sessionStorage.getItem('token')) {
  const token = sessionStorage.getItem('token');
  const bearer = token && JSON.parse(token).devToken ? JSON.parse(token).devToken : '';
  axios.defaults.headers.common.Authorization = `Bearer ${bearer}`;
} else {
  axios.defaults.headers.common.Authorization = 'Bearer ';
}

axios.interceptors.request.use(config => ({ ...config, data: humps.decamelizeKeys(config.data) }));

axios.interceptors.response.use(
  response => humps.camelizeKeys(response) as AxiosResponse,
  error => {
    const { response: { status } = { status: 0 } } = error;
    switch (status) {
      case 401: {
        if (error.response.data.message === 'Unauthenticated.') {
          dispatchNotification('Error 401', 'Unauthenticated', 'danger');
          store.dispatch(logout());
        }
        break;
      }
      case 400: {
        dispatchNotification('Error 400', `${error.message}. ${error.response.data.message}`, 'danger');
        break;
      }
      case 422: {
        parseErrorForNotification(422, error);
        break;
      }
      case 429: {
        dispatchNotification('Error 429', `${error.message}. ${error.response.data.message}`, 'danger');
        break;
      }
      case 500: {
        store.dispatch(logout());
        break;
      }
      default:
        break;
    }

    return Promise.reject(error);
  },
);

export default (): null => null;
