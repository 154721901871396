import React, { memo, ReactElement, useCallback, useState } from 'react';

// Redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import { getLineAnalytics, GetLineAnalyticsOptionTypes } from '../../actions/analyticsActions';

// Libraries
import { Line } from 'react-chartjs-2';
import { DatePicker, Dropdown, Icon, Menu, TimePicker } from 'antd';
import moment, { Moment } from 'moment';

// Components
import Select from '../../components/select';

// Types
import { DrawerProps } from './analyticsPage';
import { ThunkDispatch } from 'redux-thunk';
import { AppActions } from '../../types/actionsTypes';
import { APIGetGetGeneralAnalyticsResponse } from '../../utils/apiTypes';
import { RangePickerValue } from 'antd/lib/date-picker/interface';

const lineChartConfig = (state: boolean | null | undefined): {} => ({
  legend: { display: false },
  scales: {
    yAxes: [
      // {
      //   stacked: false,
      // },
      {
        // ticks: { min: 0, max: 100 },
        ticks: { fontColor: state ? '#c0c0c0' : '#a3a3a3' },
        gridLines: {
          color: '#c0c0c0',
        },
      },
    ],
    xAxes: [
      // {
      //   stacked: false,
      // },
      {
        // ticks: { min: 0, max: 100 },
        ticks: { fontColor: state ? '#c0c0c0' : '#a3a3a3' },
        gridLines: {
          color: '#c0c0c0',
        },
      },
    ],
  },
});

interface Props {
  data: { day?: string; hour?: string; count: number }[] | null;
  moreInfo: (item: DrawerProps) => void;
  item?: { number: number; description: string };
  selectOptions: SelectedProps[];
  darkMode: boolean;
  getLineAnalytics?: (options: GetLineAnalyticsOptionTypes) => Promise<APIGetGetGeneralAnalyticsResponse>;
}

interface SelectedProps {
  value: string;
  label: string;
}

interface SelectTypes {
  value: SelectedProps | null;
  isLoading: boolean;
  isDisabled: boolean;
}

interface RangeSelectTypes {
  momentValue: [Moment, Moment] | undefined;
  stringValue?: string[];
  isDisabled: boolean;
}

interface TimeRangeSelectTypes {
  momentValue: { start: Moment; end: Moment };
  stringValue: string[];
  isDisabled: boolean;
}

const ChartLine = ({ data, moreInfo, selectOptions, darkMode, getLineAnalytics }: Props): ReactElement => {
  const defaultSelectedValue = { value: '1', label: 'Last day' };
  const [lineChartSelect, setLineChartSelect] = useState<SelectTypes>({
    value: defaultSelectedValue,
    isLoading: false,
    isDisabled: false,
  });
  const [lineRangeSelect, setLineRangeSelect] = useState<RangeSelectTypes>({
    momentValue: undefined,
    isDisabled: false,
  });
  const [timeRangeSelect, setTimeRangeSelect] = useState<TimeRangeSelectTypes>({
    momentValue: { start: moment('00:00', 'HH:mm'), end: moment('23:59', 'HH:mm') },
    stringValue: ['00:00', '23:59'],
    isDisabled: false,
  });

  const constructData =
    data !== null
      ? {
          labels: data.map(item => item.day || item.hour),
          datasets: [
            {
              fill: false,
              lineTension: 0.1,
              backgroundColor: '#1ABC9C',
              borderColor: '#1ABC9C',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: '#1ABC9C',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 3,
              pointHoverRadius: 8,
              pointHoverBackgroundColor: '#1ABC9C',
              pointHoverBorderColor: 'rgba(220,220,220,1)',
              pointHoverBorderWidth: 2,
              pointRadius: 4,
              pointHitRadius: 10,
              data: data.map(item => item.count),
            },
          ],
        }
      : {};

  const lineSelectOnChange = useCallback(
    (e): void => {
      setLineChartSelect({ ...lineChartSelect, isLoading: true, value: e });
      setLineRangeSelect({ ...lineRangeSelect, momentValue: undefined });

      const object: GetLineAnalyticsOptionTypes = { days: parseInt(e.value as string, 10) };
      if (parseInt(e.value as string, 10) === 1) {
        object.startTime = timeRangeSelect.stringValue[0];
        object.endTime = timeRangeSelect.stringValue[1];
      }
      getLineAnalytics &&
        getLineAnalytics(object).then(() => setLineChartSelect({ ...lineChartSelect, isLoading: false, value: e }));
    },
    [lineChartSelect, timeRangeSelect],
  );

  const lineRangeOnChange = useCallback(
    (e: RangePickerValue, dates: string[]): void => {
      if (dates[0] !== '' && dates[1] !== '') {
        setLineChartSelect({ ...lineChartSelect, value: null });

        const object: GetLineAnalyticsOptionTypes = {
          startDate: moment(dates[0], 'YYYY-MM-DD').format('DD-MM-YYYY'),
          endDate: moment(dates[1], 'YYYY-MM-DD').format('DD-MM-YYYY'),
        };
        if (dates[0] === dates[1]) {
          object.startTime = timeRangeSelect.stringValue[0];
          object.endTime = timeRangeSelect.stringValue[1];
        }
        getLineAnalytics &&
          getLineAnalytics(object).then(() =>
            setLineRangeSelect({ ...lineRangeSelect, momentValue: e as [Moment, Moment], stringValue: dates }),
          );
      } else {
        setLineRangeSelect({ ...lineRangeSelect, momentValue: undefined });
        getLineAnalytics &&
          getLineAnalytics({ days: 1, startTime: '00:00', endTime: '23:59' }).then(() =>
            setLineChartSelect({ ...lineChartSelect, isLoading: true, value: { value: '1', label: 'Last day' } }),
          );
      }
    },
    [timeRangeSelect, lineRangeSelect],
  );

  const startTimeOnChange = (momentTime: Moment, stringTime: string): void => {
    const object: GetLineAnalyticsOptionTypes = {
      startTime: stringTime ? stringTime : '00:00',
      endTime: timeRangeSelect.stringValue[1],
    };

    if (lineChartSelect.value) {
      object.days = parseInt(lineChartSelect.value.value, 10);
    } else if (lineRangeSelect.momentValue) {
      object.startDate =
        lineRangeSelect.stringValue && moment(lineRangeSelect.stringValue[0], 'YYYY-MM-DD').format('DD-MM-YYYY');
      object.endDate =
        lineRangeSelect.stringValue && moment(lineRangeSelect.stringValue[1], 'YYYY-MM-DD').format('DD-MM-YYYY');
    }

    getLineAnalytics &&
      getLineAnalytics(object).then(() =>
        setTimeRangeSelect({
          ...timeRangeSelect,
          momentValue: { ...timeRangeSelect.momentValue, start: momentTime ? momentTime : moment('00:00', 'HH:mm') },
          stringValue: [stringTime ? stringTime : '00:00', timeRangeSelect.stringValue[1]],
        }),
      );
  };

  const endTimeOnChange = (momentTime: Moment, stringTime: string): void => {
    const object: GetLineAnalyticsOptionTypes = {
      startTime: timeRangeSelect.stringValue[0],
      endTime: stringTime ? stringTime : '23:59',
    };

    if (lineChartSelect.value) {
      object.days = parseInt(lineChartSelect.value.value, 10);
    } else if (lineRangeSelect.momentValue) {
      object.startDate =
        lineRangeSelect.stringValue && moment(lineRangeSelect.stringValue[0], 'YYYY-MM-DD').format('DD-MM-YYYY');
      object.endDate =
        lineRangeSelect.stringValue && moment(lineRangeSelect.stringValue[1], 'YYYY-MM-DD').format('DD-MM-YYYY');
    }

    getLineAnalytics &&
      getLineAnalytics(object).then(() =>
        setTimeRangeSelect({
          ...timeRangeSelect,
          momentValue: { ...timeRangeSelect.momentValue, end: momentTime ? momentTime : moment('23:59', 'HH:mm') },
          stringValue: [timeRangeSelect.stringValue[0], stringTime ? stringTime : '23:59'],
        }),
      );
  };

  return (
    <div className="analytics-page__chart-line analytics-page__chart-line-1">
      <div className="analytics-page__chart-line-wrapper">
        <div className="analytics-page__chart-line_header-wrapper">
          <div className="analytics-page__chart-line_header">Number of entries</div>
          <div className="analytics-page__chart-line_selectors-wrapper">
            {((lineChartSelect.value && lineChartSelect.value.value === '1') ||
              (lineRangeSelect.stringValue && lineRangeSelect.stringValue[0] === lineRangeSelect.stringValue[1])) && (
              <div className="analytics-page__chart-line_time-wrapper">
                <TimePicker
                  popupClassName="timeRange-dark-mode"
                  placeholder="Start time"
                  format="HH:mm"
                  value={timeRangeSelect.momentValue.start}
                  onChange={startTimeOnChange}
                />
                <span className="analytics-page__chart-line_time-wrapper-separator">-</span>
                <TimePicker
                  popupClassName="timeRange-dark-mode"
                  placeholder="End time"
                  format="HH:mm"
                  value={timeRangeSelect.momentValue.end}
                  onChange={endTimeOnChange}
                />
              </div>
            )}
            <DatePicker.RangePicker
              dropdownClassName="rangePicker-dark-mode"
              value={lineRangeSelect.momentValue}
              onChange={lineRangeOnChange}
              style={{ marginBottom: 10 }}
            />
            <Select
              onChange={lineSelectOnChange}
              value={lineChartSelect.value}
              placeholder="Filter by days"
              options={selectOptions}
            />
          </div>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  onClick={(): void =>
                    moreInfo({ name: 'Line Chart', infoType: 'lineChart', data: { mainData: data } })
                  }
                >
                  More info
                </Menu.Item>
              </Menu>
            }
            placement="bottomRight"
            trigger={['click']}
            getPopupContainer={(): HTMLElement =>
              document.getElementsByClassName(`analytics-page__chart-line-${1}`)[0] as HTMLElement
            }
          >
            <Icon className="analytics-page__overview-more-button" type="more" />
          </Dropdown>
        </div>

        <Line width={500} height={130} data={constructData} options={lineChartConfig(darkMode)} />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, void, AppActions>): {} => ({
  getLineAnalytics: bindActionCreators(getLineAnalytics, dispatch),
});

export default memo(connect(null, mapDispatchToProps)(ChartLine));
