import api from '../utils/api';
import { Dispatch } from 'react';

import {
  GET_CHART_ANALYTICS,
  GET_GENERAL_ANALYTICS,
  GET_LINE_ANALYTICS,
  SET_GLOBAL_LOADING,
} from '../constants/actionTypes';

export interface GetGeneralAnalyticsOptionTypes {
  days?: number;
  allData: 1 | 0;
  start?: string;
  end?: string;
}

export interface GetChartAnalyticsOptionTypes {
  days?: number;
  start?: string;
  end?: string;
}

export interface GetLineAnalyticsOptionTypes {
  days?: number;
  startDate?: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
}

export const getGeneralAnalytics = (options: GetGeneralAnalyticsOptionTypes) => (
  dispatch: Dispatch<{}>,
): Promise<{}> => {
  dispatch({
    type: SET_GLOBAL_LOADING,
    payload: true,
  });
  return new Promise((resolve, reject) =>
    api.analytics
      .getGeneralAnalytics(options)
      .then(res => {
        dispatch({
          type: GET_GENERAL_ANALYTICS,
          payload: res.data,
        });
        dispatch({
          type: SET_GLOBAL_LOADING,
          payload: false,
        });
        return resolve(res);
      })
      .catch(err => reject(err)),
  );
};

export const getChartAnalytics = (options: GetChartAnalyticsOptionTypes) => (dispatch: Dispatch<{}>): Promise<{}> => {
  dispatch({
    type: SET_GLOBAL_LOADING,
    payload: true,
  });
  return new Promise((resolve, reject) =>
    api.analytics
      .getChartAnalytics(options)
      .then(res => {
        dispatch({
          type: GET_CHART_ANALYTICS,
          payload: res.data,
        });
        dispatch({
          type: SET_GLOBAL_LOADING,
          payload: false,
        });
        return resolve(res);
      })
      .catch(err => reject(err)),
  );
};

export const getLineAnalytics = (options: GetLineAnalyticsOptionTypes) => (dispatch: Dispatch<{}>): Promise<{}> => {
  dispatch({
    type: SET_GLOBAL_LOADING,
    payload: true,
  });
  return new Promise((resolve, reject) =>
    api.analytics
      .getLineAnalytics(options)
      .then(res => {
        dispatch({
          type: GET_LINE_ANALYTICS,
          payload: res.data,
        });
        dispatch({
          type: SET_GLOBAL_LOADING,
          payload: false,
        });
        return resolve(res);
      })
      .catch(err => reject(err)),
  );
};
