import { LOGIN, LOGOUT, SET_LOGIN_FROM_SESSION, GET_USER, UPDATE_USER } from '../constants/actionTypes';
import { AuthActionTypes } from '../types/authActionTypes';

export interface AuthTypes {
  login: object | boolean | null;
  user: User | null;
}

export interface User {
  id: number;
  username?: string;
  hiddenColumns?: string[];
  liveUpdate?: boolean;
  role?: string;
  permissions: {
    id: number;
    name: string;
  }[];
}

const initialState: AuthTypes = {
  login: null,
  user: null,
};

export default function authReducer(state = initialState, action: AuthActionTypes): AuthTypes {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        login: action.payload,
      };
    case SET_LOGIN_FROM_SESSION:
      return {
        ...state,
        login: action.payload,
      };
    case GET_USER:
      return {
        ...state,
        user: {
          ...action.payload,
        },
      };
    case LOGOUT:
      return {
        ...initialState,
        login: false,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
}
