import React, { ReactElement, ReactNode, useEffect, Suspense } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { bindActionCreators } from 'redux';

import { setLoginFromSession, logout } from './actions/authActions';
import { getUser } from './actions/userActions';
import { toggleDarkMode } from './actions/appActions';
import { AppActions } from './types/actionsTypes';

import Loader from './components/loader';
import UnauthenticatedApp from './unauthenticatedApp';
import AuthenticatedApp from './authenticatedApp';
import ReactNotification from 'react-notifications-component';
import history from './utils/history';

import { AppState } from './store';
import { AuthTypes } from './reducers/authReducer';

// const AuthenticatedApp = React.lazy(() => import('./authenticatedApp'));
// const UnauthenticatedApp = React.lazy(() => import('./unauthenticatedApp'));

import './app.sass';
import './app-dark-mode.sass';

type AppTypes = {
  children?: ReactNode;
  setLoginFromSession?: (token: string) => AppActions;
  logout?: () => AppActions;
  getUser?: () => Promise<unknown>;
  toggleDarkMode?: (state: boolean) => void;
  auth: AuthTypes;
  darkMode: boolean;
};

const App = (props: AppTypes): ReactElement => {
  useEffect(() => {
    const token = window.sessionStorage.getItem('token');
    if (!token) {
      history.push('/');
      props.logout && props.logout();
    } else {
      props.getUser && props.getUser().then(() => props.setLoginFromSession && props.setLoginFromSession(token));
    }

    // DARK MODE SETUP LOGIC AT FIRST MOUNT
    const localStorageDarkMode = window.localStorage.getItem('darkMode');
    if (localStorageDarkMode && localStorageDarkMode === 'true') {
      props.toggleDarkMode && props.toggleDarkMode(true);
      const body = document.body;
      body.classList.add('dark-mode-app');
    }
  }, []);

  if (props.auth.login === null) {
    return (
      <div className="suspense-loading-wrapper">
        <Loader loading={true} />
      </div>
    );
  }

  const {
    children,
    auth: { login, user },
  } = props;

  return (
    <Suspense
      fallback={
        <div className="suspense-loading-wrapper">
          <Loader loading />
        </div>
      }
    >
      <ReactNotification />
      {login !== null && login !== false && user !== null ? (
        <AuthenticatedApp darkMode={props.darkMode}>{children}</AuthenticatedApp>
      ) : (
        <UnauthenticatedApp />
      )}
    </Suspense>
  );
};

interface LinkStateProps {
  auth: AuthTypes;
  darkMode: boolean;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  auth: state.auth,
  darkMode: state.app.darkMode,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<unknown, unknown, AppActions>): unknown => ({
  setLoginFromSession: bindActionCreators(setLoginFromSession, dispatch),
  logout: bindActionCreators(logout, dispatch),
  getUser: bindActionCreators(getUser, dispatch),
  toggleDarkMode: bindActionCreators(toggleDarkMode, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
