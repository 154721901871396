import axios, { AxiosResponse } from 'axios';

export default {
  auth: {
    login: (data: object): Promise<AxiosResponse> => axios.post('/login', data),
    logout: (): Promise<AxiosResponse> => axios.get('/logout'),
  },
  user: {
    getUser: (): Promise<AxiosResponse> => axios.get('/get-auth-user'),
    updateUser: (userId: string | number, data: object): Promise<AxiosResponse> => axios.put(`/users/${userId}`, data),
  },
  entity: {
    getEntity: (params: string): Promise<AxiosResponse> => axios.get(`/entities/${params}`),
    downloadCsv: (data: any): Promise<AxiosResponse> => axios.post(`/entities/download`, data),
  },
  analytics: {
    getGeneralAnalytics: (options: {}): Promise<AxiosResponse> => axios.post('/analytics/general', options),
    getChartAnalytics: (options: {}): Promise<AxiosResponse> => axios.post('/analytics/charts', options),
    getLineAnalytics: (options: {}): Promise<AxiosResponse> => axios.post('/analytics/line', options),
  },
  admin: {
    getUsers: (): Promise<AxiosResponse> => axios.get('/users/'),
    showUser: (userId: string | number): Promise<AxiosResponse> => axios.get(`/users/${userId}`),
    createUser: (data: object): Promise<AxiosResponse> => axios.post('/users/', data),
    deleteUser: (userId: string | number): Promise<AxiosResponse> => axios.delete(`/users/${userId}`),
  },
  permissions: {
    getPermissions: (): Promise<AxiosResponse> => axios.get('permissions'),
    addPermissionsToUser: (id: string | number, permissions: string[]): Promise<AxiosResponse> =>
      axios.post(`/permissions/add-permissions-to-user/${id}`, { permissions }),
  },
  serialNumbers: {
    add: (userId: number, serialNumber: string): Promise<AxiosResponse> =>
      axios.post(`/serial-numbers/add/${userId}`, { numbers: [serialNumber] }),
    delete: (serialNumberId: number): Promise<AxiosResponse> =>
      axios.delete(`/serial-numbers/delete/${serialNumberId}`),
  },
  devices: {
    getDevicesList: (): Promise<AxiosResponse> => axios.get('/devices/list'),
    getAvailableTimezones: (): Promise<AxiosResponse> => axios.get('/devices/timezones'),
    updateTimezone: (data: { unitSerial: string; value: string }) => axios.put(`/devices/settings/timezone`, data),
  },
};
