import api from '../utils/api';
import { Dispatch } from 'react';

import humps from 'humps';
import moment from 'moment';

import { GET_ENTITY, LOAD_ENTITY_PARAMS, SET_DOWNLOAD_LOADING, SET_GLOBAL_LOADING } from '../constants/actionTypes';
import { GetEntityParams } from '../utils/apiTypes';
import { AppActions } from '../types/actionsTypes';

const getParams = (
  pageSize: number | undefined | null = 15,
  currentPage: number | undefined = 1,
  sortColumnName: string | undefined | null = 'id',
  sortDirection: string | undefined | null = 'desc',
  filter: { columnName: string; columnText: string }[] | null | undefined,
  period: string,
): string => {
  let params = '';

  filter &&
    filter.forEach(item => {
      if (!params) {
        params = `?search_conditions[${humps.decamelize(item.columnName)}]=${item.columnText}`;
      } else {
        params = `${params}&search_conditions[${humps.decamelize(item.columnName)}]=${item.columnText}`;
      }
    });
  params = `${pageSize}/${currentPage}${
    sortDirection ? `/${humps.decamelize(sortColumnName as string)}/${sortDirection}` : ''
  }/${period}${filter ? params : ''}`;

  return params;
};

const getParamsForDownload = (
  sortColumnName: string | undefined = 'id',
  sortDirection: string | undefined = 'desc',
  filter: { columnName: string; columnText: string }[] | null | undefined,
): string => {
  let params = '';

  filter &&
    filter.forEach(item => {
      if (!params) {
        params = `?search_conditions[${humps.decamelize(item.columnName)}]=${item.columnText}`;
      } else {
        params = `${params}&search_conditions[${humps.decamelize(item.columnName)}]=${item.columnText}`;
      }
    });
  params = `${sortDirection ? `${humps.decamelize(sortColumnName as string)}/${sortDirection}` : ''}${
    filter ? params : ''
  }`;

  return params;
};

export const setDownloadLoading = (loadingState: boolean): AppActions => ({
  type: SET_DOWNLOAD_LOADING,
  payload: loadingState,
});

export const getEntity = (data: GetEntityParams): {} => (dispatch: Dispatch<{}>): Promise<{}> => {
  const { pageSize = 15, currentPage = 1, sortColumnName = 'id', sortDirection = 'desc', filter, period = '5' } = data;
  const params = getParams(pageSize, currentPage, sortColumnName, sortDirection, filter, period);
  dispatch({ type: SET_GLOBAL_LOADING, payload: true });

  return new Promise((resolve, reject) =>
    api.entity
      .getEntity(params)
      .then(res => {
        dispatch({
          type: GET_ENTITY,
          payload: res.data,
        });
        dispatch({ type: SET_GLOBAL_LOADING, payload: false });
        return resolve(res.data);
      })
      .catch(err => {
        dispatch({ type: SET_GLOBAL_LOADING, payload: false });
        return reject(err);
      }),
  );
};

export const loadEntityParams = (entityParams: GetEntityParams): AppActions => ({
  type: LOAD_ENTITY_PARAMS,
  payload: entityParams,
});

export const downloadEntityCsv = (data: GetEntityParams | null): {} => (dispatch: Dispatch<{}>): Promise<{}> => {
  dispatch({ type: SET_GLOBAL_LOADING, payload: true });
  dispatch({ type: SET_DOWNLOAD_LOADING, payload: true });

  return new Promise((resolve, reject) =>
    api.entity
      .downloadCsv(data)
      .then(res => {
        const date = moment(new Date()).format('YYYY-MM-DD');
        const blob = new Blob([res.data], { type: 'text/csv' });
        const a = document.createElement('a');
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        a.style = 'display: none';
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        a.download = `events_export_${data && data.filte && data.filter.length ? 'filtered' : 'all'}_${date}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);

        dispatch({ type: SET_GLOBAL_LOADING, payload: false });
        dispatch({ type: SET_DOWNLOAD_LOADING, payload: false });
        return resolve(res.data);
      })
      .catch(err => {
        dispatch({ type: SET_GLOBAL_LOADING, payload: false });
        dispatch({ type: SET_DOWNLOAD_LOADING, payload: false });
        return reject(err);
      }),
  );
};
