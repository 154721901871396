import { TOGGLE_BAR, SET_GLOBAL_LOADING } from '../constants/actionTypes';
import { NavBarActionTypes } from '../types/navBarActionTypes';

export interface NavBarTypes {
  expanded: boolean;
  globalLoading: boolean;
}

const initialState: NavBarTypes = {
  expanded: false,
  globalLoading: false,
};

export default function navBarReducer(state = initialState, action: NavBarActionTypes): NavBarTypes {
  switch (action.type) {
    case TOGGLE_BAR:
      return {
        ...state,
        expanded: !state.expanded,
      };
    case SET_GLOBAL_LOADING:
      return {
        ...state,
        globalLoading: action.payload,
      };
    default:
      return state;
  }
}
