import React, { memo, ReactElement } from 'react';

import { Doughnut } from 'react-chartjs-2';
import { Dropdown, Icon, Menu, Skeleton, Empty } from 'antd';
import { DrawerProps } from './analyticsPage';

const dynamicColors = (state: boolean | null | undefined): string => {
  const r = Math.floor(Math.random() * 255);
  const g = Math.floor(Math.random() * 255);
  const b = Math.floor(Math.random() * 255);
  return 'rgba(' + r + ',' + g + ',' + b + `, ${state ? '0.5' : '0.5'})`;
};

interface Props {
  moreInfo: (item: DrawerProps) => void;
  data: {
    countries: {};
    networks: {};
    devices: {};
    hotspots: {};
  } | null;
  darkMode: boolean;
}

const emptyLoading = [1, 2, 3, 4];

type piesNames = 'countries' | 'networks' | 'devices' | 'hotspots';
interface ChartData {
  data: [];
  total: number;
}

const DoughnutCharts = ({ data, moreInfo, darkMode }: Props): ReactElement => {
  const normalizeData = (data: [], key: string): {} => {
    const chartInfo: {}[] = data.map((item: { count: number }) => item.count);
    return {
      labels: data.map((item: {}[]) => {
        if (Object.values(item).length) {
          if (key === 'networks') return Object.values(item)[1];
          return Object.values(item)[0];
        }
        return 'No item';
      }),
      datasets: [
        {
          borderColor: darkMode ? '#d2d6d9' : '#fff',
          data: chartInfo,
          backgroundColor: data.map(() => dynamicColors(darkMode)),
        },
      ],
    };
  };

  return (
    <>
      <div className="analytics-page__chart-wrapper">
        {data
          ? Object.keys(data).map((key, index) => {
              const chartData = data[key as piesNames] as ChartData;
              const chartDataOptimized = normalizeData(chartData.data, key);
              if (index === 4) return null;
              return (
                <div key={key} className="analytics-page__chart">
                  <div className={`analytics-page__chart-content analytics-page__chart-content-${index}`}>
                    <div className="analytics-page__chart-header-wrapper">
                      <div className="analytics-page__chart-header">{key.charAt(0).toUpperCase() + key.slice(1)}</div>
                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item
                              onClick={(): void =>
                                moreInfo({
                                  name: `Chart with index ${index}`,
                                  infoType: 'doughnutChart',
                                  data: { mainData: chartDataOptimized },
                                })
                              }
                            >
                              More info
                            </Menu.Item>
                          </Menu>
                        }
                        placement="bottomRight"
                        trigger={['click']}
                        getPopupContainer={(): HTMLElement =>
                          document.getElementsByClassName(`analytics-page__chart-content-${index}`)[0] as HTMLElement
                        }
                      >
                        <Icon className="analytics-page__overview-more-button" type="more" />
                      </Dropdown>
                    </div>
                    <div className="analytics-page__chart-total">
                      <span className="analytics-page__chart-total_description">Total number of {key}</span>
                      <span className="analytics-page__chart-total_number">{(chartData as ChartData).total}</span>
                    </div>
                    <div className="analytics-page__chart-doughnut">
                      {chartData.data.length > 0 ? (
                        <Doughnut
                          width={24}
                          height={14}
                          legend={{
                            position: 'bottom',
                            labels: { boxWidth: 20, fontColor: '#a3a3a3' },
                          }}
                          data={chartDataOptimized}
                        />
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          : emptyLoading.map(key => {
              return (
                <div key={`empty-loading-pie-${key}`} className="analytics-page__chart">
                  <div className="analytics-page__chart-content">
                    <div className="analytics-page__chart-doughnut">
                      <Skeleton active paragraph={{ rows: 5 }} />
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
    </>
  );
};

export default memo(DoughnutCharts);
