import React, { ChangeEvent, ReactElement, memo, useState, useCallback } from 'react';

import { Icon, Popconfirm, Switch } from 'antd';

// Components
import Input from '../../../components/input/input';

// Types
import { Credentials } from './adminSettings';
import { AdminUserObject } from '../../../reducers/adminReducer';

interface Permissions {
  [name: string]: boolean;
}

interface Props {
  createUser?: (data: object) => Promise<AdminUserObject>;
  allPermissions: {
    id: number;
    name: string;
  }[];
  addPermissionsToUser?: (userId: number, permissions: string[] | null[]) => Promise<AdminUserObject>;
}

const CreateUser = ({ createUser, allPermissions, addPermissionsToUser }: Props): ReactElement => {
  // Set up all permission from BE
  const permissionsDefault: {
    [name: string]: boolean;
  } = {};
  allPermissions.forEach(p => (permissionsDefault[p.name] = false));

  const [credentials, setCredentials] = useState<Credentials>({
    username: '',
    password: '',
    passwordConfirmation: '',
  });
  const [permissions, setPermissions] = useState<Permissions>(permissionsDefault);

  const createUserInputOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>): void => {
      setCredentials({ ...credentials, [e.target.name]: e.target.value });
    },
    [credentials],
  );

  const permissionOnChange = useCallback(
    (value: boolean, permissionName: string): void => {
      setPermissions({ ...permissions, [permissionName]: value });
    },
    [permissions],
  );

  const isUserCreateValid = ({ username, password, passwordConfirmation }: Credentials): boolean => {
    return !!(username && password === passwordConfirmation);
  };

  const confirmCreation = (): void => {
    if (isUserCreateValid(credentials))
      createUser &&
        createUser({ ...credentials, role: 'user' }).then(
          res =>
            addPermissionsToUser &&
            addPermissionsToUser(
              res.id,
              Object.keys(permissions).filter(key => permissions[key]),
            ),
        );
  };

  const buildPermissions = (): ReactElement[] | null => {
    if (allPermissions.length) {
      return allPermissions.map(permission => {
        const name = permission.name.split('-').join(' ');
        return (
          <div key={`permission-element-${permission.id}`}>
            <span>{name.charAt(0).toUpperCase() + name.slice(1)}</span>
            <Switch
              checked={permissions[permission.name]}
              onChange={(state): void => permissionOnChange(state, permission.name)}
            />
          </div>
        );
      });
    }

    return null;
  };

  return (
    <Popconfirm
      overlayClassName="settings-page__create-pop-confirm popover-component-dark-mode"
      placement="bottomLeft"
      title={
        <div className="settings-page__pop-up-wrapper">
          <div className="settings-page__pop-up-user-creation-inputs">
            <Input
              name="username"
              placeholder="username"
              value={credentials.username}
              onChange={createUserInputOnChange}
            />
            <Input
              name="password"
              type="password"
              placeholder="password"
              value={credentials.password}
              onChange={createUserInputOnChange}
            />
            <Input
              name="passwordConfirmation"
              type="password"
              placeholder="repeat password"
              value={credentials.passwordConfirmation}
              onChange={createUserInputOnChange}
            />
          </div>
          <div className="settings-page__pop-up-user-creation-toggles">
            <div className="settings-page__pop-up-user-creation-toggles_title">Permissions</div>
            {buildPermissions()}
          </div>
        </div>
      }
      onConfirm={confirmCreation}
      okText="Create"
      cancelText="Cancel"
    >
      <div className="settings-page__create-wrapper">
        <Icon type="plus-circle" />
        <span>Create user</span>
      </div>
    </Popconfirm>
  );
};

export default memo(CreateUser);
