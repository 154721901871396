type RoutesType = {
  HOME: string;
  ANALYTICS: string;
  UNAUTHORIZED: string;
  SETTINGS: string;
  INSIGHTS: string;
  DEVICES: string;
};

const routesPath: RoutesType = {
  HOME: '/',
  ANALYTICS: '/analytics',
  UNAUTHORIZED: '/unauthorized',
  SETTINGS: '/settings',
  INSIGHTS: '/insights',
  DEVICES: '/devices',
};

export const HOME = 'Events';
export const ANALYTICS = 'Analytics';
export const SETTINGS = 'Settings';
export const INSIGHTS = 'Insights';
export const DEVICES = 'Devices';

export enum AuthRoutes {
  dashboard = '/',
  analytics = '/analytics',
  settings = '/settings',
  insights = '/insights',
  devices = '/devices',
}

export enum NonAuthRoutes {
  login = '/login',
  unauthorized = '/unauthorized',
}

export default routesPath;
