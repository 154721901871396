import React, { ReactElement, memo } from 'react';
import { Dropdown, Icon, Menu, Empty } from 'antd';
import { DrawerProps } from './analyticsPage';

interface Props {
  index: number;
  moreInfo: (item: DrawerProps) => void;
  data: { mainData: { name: string; count: number } }[] | null;
  tableName: string;
}

const OverviewSquare = ({ index, moreInfo, data, tableName }: Props): ReactElement => {
  const openDrawer = (): void => {
    moreInfo({ infoType: 'rectangle', name: tableName, data });
  };
  return (
    <div className={`analytics-page__overview-rectangle analytics-page__overview-rectangle-${index}`}>
      <div className="analytics-page__overview-rectangle_wrapper">
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={openDrawer}>More info</Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
          getPopupContainer={(): HTMLElement =>
            document.getElementsByClassName(`analytics-page__overview-rectangle-${index}`)[0] as HTMLElement
          }
        >
          <Icon className="analytics-page__overview-more-button" type="more" />
        </Dropdown>
        <div className="analytics-page__overview-rectangle_header">{tableName}</div>
        <div className="analytics-page__overview-rectangle_content">
          {data && data.length ? (
            data.map((item, key) => {
              if (key < 3) {
                return (
                  <div key={`${tableName}-row=${key}`} className="analytics-page__overview-rectangle_content--row">
                    <div className="analytics-page__overview-rectangle_content--country">1. {item.mainData.name}</div>
                    <div className="analytics-page__overview-rectangle_content--number">{item.mainData.count}</div>
                  </div>
                );
              }
              return null;
            })
          ) : (
            <div className="analytics-page__overview-rectangle_content--no-data">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(OverviewSquare);
