import axios from 'axios';

import { LOGIN, LOGOUT, SET_LOGIN_FROM_SESSION } from '../constants/actionTypes';
import api from '../utils/api';
import { Dispatch } from 'react';
import { AppActions } from '../types/actionsTypes';
import { ApiTypes } from '../utils/apiTypes';

export const login = (data: object) => (dispatch: Dispatch<AppActions>): Promise<ApiTypes> =>
  new Promise((resolve, reject) =>
    api.auth
      .login(data)
      .then(res => {
        sessionStorage.setItem('token', JSON.stringify(res.data));
        const bearer = res.data && res.data.devToken ? res.data.devToken : '';
        axios.defaults.headers.common.Authorization = `Bearer ${bearer}`;
        dispatch({
          type: LOGIN,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch(err => reject(err)),
  );

export const logout = (): AppActions => {
  window.sessionStorage.removeItem('token');
  return {
    type: LOGOUT,
  };
};

export const apiLogout = (): AppActions => {
  window.sessionStorage.removeItem('token');
  api.auth.logout();
  return {
    type: LOGOUT,
  };
};

export const setLoginFromSession = (): AppActions => {
  const token = window.sessionStorage.getItem('token');
  if (token && token !== 'undefined' && token !== 'null') {
    return {
      type: SET_LOGIN_FROM_SESSION,
      payload: JSON.parse(token),
    };
  } else {
    return {
      type: LOGOUT,
    };
  }
};
