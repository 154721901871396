// AUTH
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_LOGIN_FROM_SESSION = 'SET_LOGIN_FROM_SESSION';
export const TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE';

// USER
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';

// NAV BAR
export const TOGGLE_BAR = 'TOGGLE_BAR';
export const SET_GLOBAL_LOADING = 'SET_GLOBAL_LOADING';

// ENTITY
export const GET_ENTITY = 'GET_ENTITY';
export const LOAD_ENTITY_PARAMS = 'LOAD_ENTITY_PARAMS';
export const GET_HIDDEN_COLUMNS = 'GET_HIDDEN_COLUMNS';
export const SET_DOWNLOAD_LOADING = 'SET_DOWNLOAD_LOADING';
// export const PUT_HIDDEN_COLUMNS = 'PUT_HIDDEN_COLUMNS';

// ANALYTICS
export const GET_ANALYTICS = 'GET_ANALYTICS';
export const GET_GENERAL_ANALYTICS = 'GET_GENERAL_ANALYTICS';
export const GET_CHART_ANALYTICS = 'GET_CHART_ANALYTICS';
export const GET_LINE_ANALYTICS = 'GET_LINE_ANALYTICS';

// ADMIN
export const ADMIN_GET_USERS = 'ADMIN_GET_USERS';
export const ADMIN_SHOW_USER = 'ADMIN_SHOW_USER';
export const ADMIN_CREATE_USER = 'ADMIN_CREATE_USER';
export const ADMIN_UPDATE_USER = 'ADMIN_UPDATE_USER';
export const ADMIN_DELETE_USER = 'ADMIN_DELETE_USER';
export const ADD_PERMISSIONS_TO_USER = 'ADD_PERMISSIONS_TO_USER';
export const ADD_SERIAL_NUMBERS_TO_USER = 'ADD_SERIAL_NUMBERS_TO_USER';
export const DELETE_SERIAL_NUMBERS_FROM_USER = 'DELETE_SERIAL_NUMBERS_FROM_USER';

// DEVICES
export const GET_DEVICES_LIST = 'GET_DEVICES_LIST';
export const GET_AVAILABLE_TIMEZONES = 'GET_AVAILABLE_TIMEZONES';
export const UPDATE_DEVICE_TIMEZONE = 'UPDATE_DEVICE_TIMEZONE';
