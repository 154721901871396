import React, { Suspense } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import routes from './constants/routes';

import App from './App';
import Loader from './components/loader';

import HomePage from './containers/HomePage';
import AnalyticsPage from './containers/AnalyticsPage';
import SettingsPage from './containers/SettingsPage';
import InsightsPage from './containers/InsightsPage';
import DevicesPage from './containers/DevicesPage';
import NotFoundPage from './containers/NotFoundPage';
import NonAuthorizedPage from './containers/NonAuthorizedPage';

import NavBarLeft from './components/navBarLeft';

import AuthRoute from './components/routes/authRoutes';

import UserRoles from './constants/userRoles';

import './app.sass';

// Lazy loading implementation
// const HomePage = React.lazy(() => import('./containers/HomePage'));
// const AnalyticsPage = React.lazy(() => import('./containers/AnalyticsPage'));
// const NotFoundPage = React.lazy(() => import('./containers/NotFoundPage'));
// const NonAuthorizedPage = React.lazy(() => import('./containers/NonAuthorizedPage'));

const Routes = (): React.ReactElement => {
  return (
    <App>
      <div className="app__wrapper">
        <NavBarLeft />
        <div className="route-container">
          <Suspense
            fallback={
              <div className="suspense-loading-wrapper">
                <Loader loading />
              </div>
            }
          >
            <Switch>
              <AuthRoute
                exact
                path={routes.HOME}
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                Component={HomePage}
                requiredRoles={UserRoles.all}
              />
              <AuthRoute
                path={routes.ANALYTICS}
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                Component={AnalyticsPage}
                requiredRoles={UserRoles.all}
              />
              <AuthRoute
                path={routes.SETTINGS}
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                Component={SettingsPage}
                requiredRoles={UserRoles.all}
              />
              <AuthRoute
                path={routes.INSIGHTS}
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                Component={InsightsPage}
                requiredRoles={UserRoles.all}
              />
              <AuthRoute
                path={routes.DEVICES}
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                Component={DevicesPage}
                requiredRoles={UserRoles.all}
              />
              <Route path={routes.UNAUTHORIZED} component={NonAuthorizedPage} />
              <Route component={NotFoundPage} />
            </Switch>
          </Suspense>
        </div>
      </div>
    </App>
  );
};

export default withRouter(Routes);
