import React, { ReactElement, memo } from 'react';
import { Icon } from 'antd';
import numeral from 'numeral';

interface Props {
  data: number;
  index: number;
}

const OverviewSquare = ({ data, index }: Props): ReactElement => {
  const getStaticData = (index: number): { description: string; icon: string } => {
    switch (index) {
      case 0:
        return { description: 'Unique IMSIs', icon: 'phone' };
      case 1:
        return { description: 'Seen Countries', icon: 'global' };
      case 2:
        return { description: 'Seen Networks', icon: 'deployment-unit' };
      case 3:
        return { description: 'Unique MAC Addresses', icon: 'appstore' };
      default:
        return { description: '', icon: '' };
    }
  };

  return (
    <div className={`analytics-page__overview-square analytics-page__overview-square-${index}`}>
      <div className="analytics-page__overview-square_wrapper">
        <div className="analytics-page__overview-square_icon">
          <Icon type={`${getStaticData(index).icon}`} />
        </div>
        <div className="analytics-page__overview-square_number">{numeral(data).format('0a')}</div>
        <div className="analytics-page__overview-square_description">{getStaticData(index).description}</div>
      </div>
    </div>
  );
};

export default memo(OverviewSquare);
