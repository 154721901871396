import { TOGGLE_DARK_MODE } from '../constants/actionTypes';
import { AppActionTypes } from '../types/appActionTypes';

export interface AuthTypes {
  darkMode: boolean;
}

export interface User {
  darkMode?: boolean;
}

const initialState: AuthTypes = {
  darkMode: false,
};

export default function appReducer(state = initialState, action: AppActionTypes): AuthTypes {
  switch (action.type) {
    case TOGGLE_DARK_MODE:
      return {
        ...state,
        darkMode: action.payload,
      };
    default:
      return state;
  }
}
