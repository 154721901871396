import { AxiosError } from 'axios';
import React, { ReactElement } from 'react';
import dispatchNotification from '../components/notification';

const parseErrorForNotification = (errorCode: number, error: AxiosError): void => {
  let title = '';
  let content: ReactElement[] | string = '';
  if (errorCode === 422) {
    if (error && error.response && error.response.data) {
      title = error.response.data.message;
      const errors = error.response.data.errors;
      content = Object.keys(errors).map((key, index) => <div key={index}>{`${key}: ${errors[key][0]}`}</div>);
      dispatchNotification(
        title,
        '',
        'danger',
        <div style={{ width: '100%' }} className="notification-danger">
          <div className="notification-content">
            <p className="notification-title">{title}</p>
            <div className="notification-message">{content}</div>
          </div>
        </div>,
      );
    }
  }
};

export default parseErrorForNotification;
