import api from '../utils/api';
import {
  ADMIN_CREATE_USER,
  ADMIN_DELETE_USER,
  ADMIN_GET_USERS,
  ADMIN_SHOW_USER,
  ADMIN_UPDATE_USER,
  ADD_PERMISSIONS_TO_USER,
  ADD_SERIAL_NUMBERS_TO_USER,
  DELETE_SERIAL_NUMBERS_FROM_USER,
} from '../constants/actionTypes';

// Types
import { Dispatch } from 'react';
import { AppActions } from '../types/actionsTypes';
import { ApiTypes } from '../utils/apiTypes';

export const adminGetUsers = () => (dispatch: Dispatch<AppActions>): Promise<ApiTypes> =>
  new Promise((resolve, reject) =>
    api.admin
      .getUsers()
      .then(res => {
        dispatch({
          type: ADMIN_GET_USERS,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch(err => reject(err)),
  );

export const adminShowUser = (userId: number | string) => (dispatch: Dispatch<AppActions>): Promise<ApiTypes> =>
  new Promise((resolve, reject) =>
    api.admin
      .showUser(userId)
      .then(res => {
        dispatch({
          type: ADMIN_SHOW_USER,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch(err => reject(err)),
  );

export const adminCreateUser = (data: object) => (dispatch: Dispatch<AppActions>): Promise<ApiTypes> =>
  new Promise((resolve, reject) =>
    api.admin
      .createUser(data)
      .then(res => {
        dispatch({
          type: ADMIN_CREATE_USER,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch(err => reject(err)),
  );

export const adminUpdateUser = (userId: string | number, data: object) => (
  dispatch: Dispatch<AppActions>,
): Promise<ApiTypes> =>
  new Promise((resolve, reject) =>
    api.user
      .updateUser(userId, data)
      .then(res => {
        dispatch({
          type: ADMIN_UPDATE_USER,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch(err => reject(err)),
  );

export const adminDeleteUser = (userId: string | number) => (dispatch: Dispatch<AppActions>): Promise<ApiTypes> =>
  new Promise((resolve, reject) =>
    api.admin
      .deleteUser(userId)
      .then(res => {
        dispatch({
          type: ADMIN_DELETE_USER,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch(err => reject(err)),
  );

export const addPermissionsToUser = (id: number, permissions: string[]) => (
  dispatch: Dispatch<AppActions>,
): Promise<ApiTypes> =>
  new Promise((resolve, reject) =>
    api.permissions
      .addPermissionsToUser(id, permissions)
      .then(res => {
        dispatch({
          type: ADD_PERMISSIONS_TO_USER,
          payload: { id, permissions: res.data.data.permissions },
        });
        return resolve(res.data);
      })
      .catch(err => reject(err)),
  );

export const addSerialNumbersToUser = (id: number, serialNumbers: string) => (
  dispatch: Dispatch<AppActions>,
): Promise<ApiTypes> =>
  new Promise((resolve, reject) =>
    api.serialNumbers
      .add(id, serialNumbers)
      .then(res => {
        dispatch({
          type: ADD_SERIAL_NUMBERS_TO_USER,
          payload: res.data.data,
        });
        return resolve(res.data);
      })
      .catch(err => reject(err)),
  );

export const deleteSerialNumbersToUser = (id: number) => (dispatch: Dispatch<AppActions>): Promise<ApiTypes> =>
  new Promise((resolve, reject) =>
    api.serialNumbers
      .delete(id)
      .then(res => {
        dispatch({
          type: DELETE_SERIAL_NUMBERS_FROM_USER,
          payload: res.data.data,
        });
        return resolve(res.data);
      })
      .catch(err => reject(err)),
  );
