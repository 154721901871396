import React, { memo, ReactElement } from 'react';
import ReactTooltip from 'react-tooltip';

import './tooltip.sass';

type Props = {
  children: Node | ReactElement | string;
  message: Node | string;
  id: string;
  position?: 'top' | 'bottom' | 'left' | 'right';
  dataEvent?: string;
  showTooltip?: boolean;
};

const Tooltip = ({ children, message, id, position, dataEvent, showTooltip }: Props): ReactElement => {
  return (
    <div className="tooltip">
      <a data-event={dataEvent} data-tip="React-tooltip" data-for={id}>
        {children}
      </a>
      {showTooltip && (
        <ReactTooltip globalEventOff="click" className="tooltip_component" place={position} id={id} effect="solid">
          <span>{message}</span>
        </ReactTooltip>
      )}
    </div>
  );
};

Tooltip.defaultProps = {
  position: 'top',
  dataEvent: '',
  showTooltip: true,
};

export default memo(Tooltip);
