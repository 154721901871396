import React, { ReactElement, useState, useEffect, MouseEvent, memo } from 'react';

import './toggle.sass';

type onClickParams = {
  event: MouseEvent<HTMLDivElement | HTMLElement>;
  toggled: boolean;
};

type Props = {
  disabled?: boolean;
  toggled?: boolean;
  name?: string;
  onClick?: (obj: onClickParams) => void;
};

const Toggle = ({ disabled = false, toggled = false, name, onClick = (): null => null }: Props): ReactElement => {
  const [checkedState, setCheckedState] = useState<boolean>(false);

  const toggle = (event: MouseEvent<HTMLDivElement>): void => {
    setCheckedState(!checkedState);
    onClick({ event, toggled: !checkedState });
  };

  useEffect(() => {
    setCheckedState(toggled);
  }, [toggled]);

  return (
    <div
      id={`checkbox${name ? `-${name}` : ''}`}
      className={`toggle-button${(checkedState || toggled) && !disabled ? ' toggle-button--active' : ''} ${
        disabled ? ' toggle-button--disabled' : ''
      }`}
      role="button"
      tabIndex={0}
      onClick={!disabled ? toggle : (): null => null}
    >
      <div className="toggle-button__circle" />
    </div>
  );
};

Toggle.defaultProps = {
  disabled: false,
  toggled: false,
  name: undefined,
};

export default memo(Toggle);
