import React, { ReactElement, useEffect, useState, memo } from 'react';

import { Table, Icon, Button, Input, DatePicker, Transfer, Popover } from 'antd';
import { PaginationConfig } from 'antd/lib/pagination';
import { SorterResult } from 'antd/lib/table';
import { Moment } from 'moment';

import { setGlobalLoading } from '../../actions/navBarActions';
import { loadEntityParams } from '../../actions/entityActions';
import { APIGetEntityResponse, GetEntityParams } from '../../utils/apiTypes';

import Toggle from '../toggle';
import Select from '../select';
import { RenderResult, TransferItem } from 'antd/lib/transfer';

const selectOptions = [
  { value: '1', label: 'Last day' },
  { value: '2', label: 'Last week' },
  { value: '3', label: 'Last month' },
  { value: '4', label: 'Last year' },
  { value: '5', label: 'All time' },
];

interface Props {
  entities: APIGetEntityResponse | null;
  getEntity?: (data: GetEntityParams) => Promise<{}>;
  setGlobalLoading?: typeof setGlobalLoading;
  loadEntityParams?: typeof loadEntityParams;
  loading?: boolean;
  hiddenColumns?: string[] | null | undefined;
  updateHiddenColumns?: (columns: string[]) => Promise<[]>;
  downloadCsv?: (data: {} | null) => void;
  showTransfer?: boolean;
  downloadLoading?: boolean;
}

const TableComponent = ({
  entities,
  getEntity,
  setGlobalLoading,
  loadEntityParams,
  loading = false,
  hiddenColumns,
  updateHiddenColumns,
  showTransfer = true,
  downloadCsv,
  downloadLoading,
}: Props): ReactElement => {
  const [columns, setColumns] = useState<{ key?: string; disabled?: boolean }[] | []>([]);
  const [mockUpTransfer, setMockUpTransfer] = useState<TransferItem[] | []>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [visibleKeys, setVisibleKeys] = useState<string[]>([]);
  const [disabledTransfer, setDisabledTransfer] = useState<boolean>(false);
  const [lastDisabledTransfer, setLastDisabledTransfer] = useState<{ index: number; columnName: string } | null>(null);
  const [tableStatusData, setTableStatusData] = useState<{
    pageSize: number | undefined | null;
    currentPage: number | undefined | null;
    sortDirection: string | undefined | null;
    sortColumnName: string | undefined | null;
    filter: { columnName: string; columnText: string }[] | null;
    period: { value: string; label: string };
  }>({
    pageSize: 10,
    currentPage: 1,
    sortDirection: 'desc',
    sortColumnName: 'id',
    filter: null,
    period: { value: '5', label: 'All time' },
  });

  let inputRef: Input | null = null;

  const handleSearch = (sKey: {}, dataIndex: string, confirm: () => void): void => {
    confirm();
  };
  const handleReset = (clearFilters: () => void): void => {
    clearFilters();
  };

  const isDateColumn = (index: string): boolean => {
    switch (index) {
      case 'dateTime':
        return true;
      case 'createdAt':
        return true;
      case 'updatedAt':
        return true;
      default:
        return false;
    }
  };
  const getColumnSearchProps = (dataIndex: string): {} => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys = [],
      confirm,
      clearFilters,
    }: {
      setSelectedKeys: ({}) => void;
      selectedKeys: { value?: string | Moment | null; isRange: boolean | null }[];
      confirm: () => void;
      clearFilters: () => void;
    }): {} => (
      <div style={{ padding: 8 }}>
        {isDateColumn(dataIndex) ? (
          <div style={{ width: 240 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Toggle
                onClick={(): void =>
                  setSelectedKeys([
                    { ...selectedKeys[0], isRange: selectedKeys.length ? !selectedKeys[0].isRange : true },
                  ])
                }
              />
              <span style={{ marginLeft: 10 }}>
                {selectedKeys && selectedKeys.length && selectedKeys[0].isRange ? 'Range Picker' : 'Date Picker'}
              </span>
            </div>
            <br />
            {selectedKeys && selectedKeys.length && selectedKeys[0].isRange ? (
              <DatePicker.RangePicker
                dropdownClassName="rangePicker-dark-mode"
                style={{ marginBottom: 10 }}
                onChange={(e): void => setSelectedKeys([{ ...selectedKeys[0], value: e }])}
              />
            ) : (
              <DatePicker
                dropdownClassName="datePicker-dark-mode"
                style={{ marginBottom: 10 }}
                onChange={(e): void => setSelectedKeys([{ ...selectedKeys[0], value: e }])}
              />
            )}
            <br />
            <Button
              type="primary"
              onClick={(): void => handleSearch(selectedKeys, dataIndex, confirm)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={(): void => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ) : (
          <>
            <Input
              ref={(node): Input | null => (inputRef = node)}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0] && selectedKeys[0].value ? (selectedKeys[0].value as string) : ''}
              onChange={(e): void => setSelectedKeys(e.target.value ? [{ value: e.target.value }] : [])}
              onPressEnter={(): void => handleSearch(selectedKeys, dataIndex, confirm)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={(): void => handleSearch(selectedKeys, dataIndex, confirm)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={(): void => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </>
        )}
      </div>
    ),
    filterIcon: (filtered: boolean): ReactElement => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible: boolean): void => {
      if (visible) {
        setTimeout(() => inputRef && inputRef.select());
      }
    },
    render: (text: string): string => text,
  });

  const columnSettings = (column: string): {} => {
    switch (column) {
      default:
        return {};
    }
  };

  // Parse function that takes un-formatted string(key in snake_case) and output readable text
  const parseColumnTitle = (title: string): string => {
    const text = title.replace('_', ' ');
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
  };

  const buildColumns = (): void => {
    const data = entities && entities.data.length !== 0 && entities.data[0];
    if (data !== null) {
      const newColumns: {}[] = [];
      const transferColumns: TransferItem[] = [];
      const keysToShow: string[] = [];
      Object.keys(data).forEach((column, index) => {
        transferColumns.push({ key: column, title: parseColumnTitle(column), description: `description${index + 1}` });
        if (hiddenColumns && !hiddenColumns.includes(column)) keysToShow.push(column);
        if (keysToShow.includes(column)) {
          newColumns.push({
            title: parseColumnTitle(column),
            dataIndex: column,
            key: column,
            sorter: true,
            ...columnSettings(column),
            ...getColumnSearchProps(column),
          });
        }
      });

      if (keysToShow.length === 1) {
        const index = transferColumns.findIndex(c => c.key === keysToShow[0]);
        transferColumns[index].disabled = true;

        setDisabledTransfer(true);
        setLastDisabledTransfer({ index, columnName: keysToShow[0] });
      }

      setColumns(newColumns);
      setMockUpTransfer(transferColumns);
      setVisibleKeys(keysToShow);
    }
  };

  const transferHandleSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]): void => {
    const newColumns = [...mockUpTransfer];

    // Checking the transfer disable functionality. If we have the last element in targetKeys wi disable it
    if (targetSelectedKeys.length === visibleKeys.length - 1 && visibleKeys.length > 1) {
      const lastColumn = visibleKeys.filter(x => !targetSelectedKeys.includes(x))[0];
      const index = newColumns.findIndex(c => c.key === lastColumn);

      newColumns[index].disabled = true;
      setMockUpTransfer(newColumns);
      setDisabledTransfer(true);
      setLastDisabledTransfer({ index, columnName: lastColumn });
    } else if (
      disabledTransfer &&
      (targetSelectedKeys.length !== 0 || visibleKeys.length === 1) &&
      sourceSelectedKeys.length === 0
    ) {
      setDisabledTransfer(false);
      lastDisabledTransfer && delete newColumns[lastDisabledTransfer.index].disabled;
      setMockUpTransfer(newColumns);
    }

    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const transferOnChange = (visibleColumns: string[]): void => {
    const data = entities && entities.data.length !== 0 && entities.data[0];
    const newColumns: {}[] = [];
    const hidden: string[] = [];

    data &&
      Object.keys(data).forEach((column: string) => {
        if (visibleColumns.includes(column)) {
          newColumns.push({
            title: parseColumnTitle(column),
            dataIndex: column,
            key: column,
            sorter: true,
            ...columnSettings(column),
            ...getColumnSearchProps(column),
          });
        }
        if (!visibleColumns.includes(column)) hidden.push(column);
      });

    setColumns(newColumns);
    setVisibleKeys(visibleColumns);
    updateHiddenColumns && updateHiddenColumns(hidden);
  };

  useEffect(() => {
    if (entities && entities.data.length !== 0 && !columns.length) {
      buildColumns();
    }
  }, [entities]);

  const onChange = (
    a: PaginationConfig,
    b: { [a: string]: { value: string | Moment | Moment[] }[] },
    c: SorterResult<{}>,
  ): void => {
    const filter: { columnName: string; columnText: string }[] = [];
    Object.keys(b).forEach(key => {
      if (b[key].length) {
        const { value } = b[key][0];
        if (key === 'dateTime' || key === 'createdAt' || key === 'updatedAt')
          filter.push({
            columnName: key,
            columnText:
              typeof value !== 'string' && !Array.isArray(value)
                ? `${value.format('YYYY-MM-DD')} 00:00:01 - ${value.format('YYYY-MM-DD')} 23-59-59`
                : typeof value !== 'string'
                ? `${value[0].format('YYYY-MM-DD')} 00:00:01 - ${value[1].format('YYYY-MM-DD')} 23:59:59`
                : '',
          });
        else {
          filter.push({
            columnName: key,
            columnText: typeof value === 'string' ? value : '',
          });
        }
      }
    });

    const convertOrder = (order: string): string | undefined => {
      switch (order) {
        case 'ascend':
          return 'asc';
        case 'descend':
          return 'desc';
        default:
          return undefined;
      }
    };

    setGlobalLoading && setGlobalLoading(true);
    const { pageSize, current: currentPage } = a;
    const { order, field: sortColumnName } = c;
    getEntity &&
      getEntity({
        pageSize,
        currentPage,
        sortDirection: order && convertOrder(order),
        sortColumnName,
        filter: filter.length ? filter : null,
        period: tableStatusData.period.value,
      }).then(() => {
        setTableStatusData({
          ...tableStatusData,
          pageSize,
          currentPage,
          sortDirection: order && convertOrder(order),
          sortColumnName,
          filter: filter.length ? filter : null,
        });
        loadEntityParams &&
          loadEntityParams({
            pageSize: pageSize,
            currentPage: currentPage,
            filter: filter,
            sortColumnName: sortColumnName,
            sortDirection: order && convertOrder(order),
            period: tableStatusData.period.value,
          });
        setGlobalLoading && setGlobalLoading(false);
      });
  };

  const downloadCsvOnClick = (): void => {
    const filters = tableStatusData && tableStatusData.filter && tableStatusData.filter;
    const camelToSnake = (key: string) => {
      const removeUppercase = key.replace(/([A-Z])/g, ' $1');
      const convertToSnake = removeUppercase
        .split(' ')
        .join('_')
        .toLowerCase();

      return convertToSnake;
    };

    let conditions = {};

    if (filters) {
      filters.map(item => {
        if (!conditions.hasOwnProperty(item.columnName)) {
          conditions = {
            ...conditions,
            [item.columnName]: item.columnText,
          };
        }
      });
    }

    const data = {
      order_by: tableStatusData && tableStatusData.sortColumnName ? camelToSnake(tableStatusData.sortColumnName) : 'id',
      order_direction: tableStatusData && tableStatusData.sortDirection ? tableStatusData.sortDirection : 'desc',
      period: tableStatusData.period.value,
      search_conditions: filters ? conditions : {},
    };

    downloadCsv && downloadCsv(data);
  };

  const chartsSelectOnChange = (selectedEl: any) => {
    setTableStatusData({ ...tableStatusData, period: selectedEl });
    const data: { period: string } = {
      ...tableStatusData,
      period: selectedEl.value,
    };
    getEntity && getEntity(data);
  };
  return (
    <>
      <Table
        className="table-component"
        rowKey="id"
        columns={columns}
        dataSource={entities ? entities.data : []}
        scroll={{ x: true }}
        size="small"
        onChange={onChange}
        pagination={{
          showSizeChanger: true,
          total: entities ? entities.meta.total : undefined,
          pageSizeOptions: ['15', '30', '60', '100', '200'],
          defaultPageSize: 15,
        }}
        loading={loading}
        title={(): ReactElement => (
          <>
            <div>
              <span className="table-component__title">Table Title</span>
              {showTransfer && (
                <Popover
                  placement="bottomLeft"
                  title="Table Settings"
                  overlayClassName="popover-component-dark-mode"
                  content={
                    <Transfer
                      dataSource={mockUpTransfer}
                      titles={['Hidden Columns', 'Active Columns']}
                      targetKeys={visibleKeys}
                      selectedKeys={selectedKeys}
                      onChange={transferOnChange}
                      onSelectChange={transferHandleSelectChange}
                      render={(item): RenderResult => item.title as RenderResult}
                      listStyle={{ width: 250, height: 280 }}
                      showSelectAll={false}
                    />
                  }
                  trigger="click"
                >
                  <Icon type="setting" />
                </Popover>
              )}
            </div>
            <div className="table-component__other-wrapper">
              <Select
                className="table-component__select select"
                value={tableStatusData.period}
                onChange={chartsSelectOnChange}
                placeholder="Filter by period"
                options={selectOptions}
              />
              <Button
                type="link"
                loading={downloadLoading}
                className="table-component__download-wrapper"
                onClick={downloadCsvOnClick}
              >
                <span>Download CSV</span> <Icon type="download" />
              </Button>
            </div>
          </>
        )}
      />
    </>
  );
};

export default memo(TableComponent);
