import humps from 'humps';

import { GET_USER, UPDATE_USER } from '../constants/actionTypes';
import api from '../utils/api';
import { Dispatch } from 'react';
import { AppActions } from '../types/actionsTypes';
import { ApiTypes } from '../utils/apiTypes';

export const getUser = () => (dispatch: Dispatch<AppActions>): Promise<ApiTypes> =>
  new Promise((resolve, reject) =>
    api.user
      .getUser()
      .then(res => {
        dispatch({
          type: GET_USER,
          payload: {
            ...res.data.data,
            eavHiddenColumns:
              res.data.data.eavHiddenColumns && res.data.data.eavHiddenColumns.map((e: string) => humps.camelize(e)),
          },
        });
        return resolve(res.data);
      })
      .catch(err => reject(err)),
  );

export const updateUser = (id: number, data: object) => (dispatch: Dispatch<AppActions>): Promise<ApiTypes> =>
  new Promise((resolve, reject) =>
    api.user
      .updateUser(id, data)
      .then(res => {
        dispatch({
          type: UPDATE_USER,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch(err => reject(err)),
  );
