import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { User } from '../../reducers/authReducer';
// Libraries

// Actions

// Components
import UserSettings from './UserSettings/userSettings';
import AdminSettings from './AdminSettings/adminSettings';

// Types

interface Props {
  user: User | null;
}
import './settingsPage.sass';

class SettingsPage extends PureComponent<Props> {
  render(): {} {
    const { user } = this.props;
    const role = user && user.role;
    return <div className="settings-page">{role === 'admin' ? <AdminSettings /> : <UserSettings />}</div>;
  }
}

type LinkStateProps = {
  user: User | null;
};

const mapStateToProps = (state: AppState): LinkStateProps => ({
  user: state.auth.user,
});

const mapDispatchToProps = (): {} => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
