import React, { ReactElement } from 'react';

import './loader.sass';

type Props = {
  loading?: boolean;
  className?: string;
};

const Loader = ({ loading = false, className = '' }: Props): ReactElement => {
  return (
    <div className={`loader${className ? ` ${className}` : ''}`}>
      <div className={`loader__lds-ellipsis${loading ? ' loader__lds-ellipsis--loading' : ''}`}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Loader;
