import React, { ReactElement, ReactNode, MouseEvent, memo } from 'react';

import './button.sass';

type Props = {
  children?: ReactNode;
  styleType?: 'default' | 'secondary' | 'primary' | 'danger';
  loading?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
  icon?: string | ReactElement;
  size?: 'small' | 'large' | 'default';
  style?: {};
  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
};

const Button = ({
  children = null,
  styleType = 'primary',
  loading = false,
  onClick,
  onFocus,
  onBlur,
  disabled = false,
  icon,
  size = 'default',
  style = {},
  className = '',
  type = 'button',
}: Props): ReactElement => (
  <button
    className={`button${size ? ` button--size-${size}` : 'button--size-default'}${
      styleType ? ` button--${styleType}` : ''
    }${className ? ` ${className}` : ''}`}
    type={type}
    onClick={onClick}
    onFocus={onFocus}
    onBlur={onBlur}
    disabled={disabled || loading}
    style={style}
  >
    {loading && (
      <div className="button__loading">
        <div />
        <div />
      </div>
    )}
    {!loading && icon && (
      <div className="button__icon">{typeof icon === 'string' ? <img src={icon} alt="icon" /> : icon}</div>
    )}
    <span>{children}</span>
  </button>
);

export default memo(Button);
