import { GET_ENTITY, LOAD_ENTITY_PARAMS, SET_DOWNLOAD_LOADING } from '../constants/actionTypes';
import { EntityActionTypes } from '../types/entityActionTypes';
import { APIGetEntityResponse, GetEntityParams } from '../utils/apiTypes';

export interface EntityTypes {
  entities: APIGetEntityResponse | null;
  params: GetEntityParams | null;
  downloadLoading: boolean;
}

const initialState: EntityTypes = {
  entities: null,
  params: null,
  downloadLoading: false,
};

export default function entity(state = initialState, action: EntityActionTypes): EntityTypes {
  switch (action.type) {
    case GET_ENTITY:
      return {
        ...state,
        entities: action.payload,
      };
    case LOAD_ENTITY_PARAMS:
      return {
        ...state,
        params: action.payload,
      };
    case SET_DOWNLOAD_LOADING:
      return {
        ...state,
        downloadLoading: action.payload,
      };
    default:
      return state;
  }
}
