import { GET_CHART_ANALYTICS, GET_GENERAL_ANALYTICS, GET_LINE_ANALYTICS } from '../constants/actionTypes';

// Types
import {
  AnalyticChartsTypes,
  AnalyticCountsTypes,
  AnalyticLineChartsTypes,
  AnalyticTopsTypes,
} from '../utils/apiTypes';
import { AnalyticActionTypes } from '../types/analyticActionTypes';

export interface AnalyticsTypes {
  general: {
    counts: AnalyticCountsTypes | null;
    tops: AnalyticTopsTypes | null;
  };
  charts: AnalyticChartsTypes | null;
  line: AnalyticLineChartsTypes | null;
}

const initialState: AnalyticsTypes = {
  general: {
    counts: null,
    tops: null,
  },
  charts: null,
  line: null,
};

export default function analytics(state = initialState, action: AnalyticActionTypes): AnalyticsTypes {
  switch (action.type) {
    case GET_GENERAL_ANALYTICS:
      return {
        ...state,
        general: action.payload,
      };
    case GET_CHART_ANALYTICS:
      return {
        ...state,
        charts: action.payload,
      };
    case GET_LINE_ANALYTICS:
      return {
        ...state,
        line: action.payload,
      };
    default:
      return state;
  }
}
