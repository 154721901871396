import { GET_DEVICES_LIST, GET_AVAILABLE_TIMEZONES, UPDATE_DEVICE_TIMEZONE } from '../constants/actionTypes';
import api from '../utils/api';
import { Dispatch } from 'react';
import { AppActions } from '../types/actionsTypes';
import { ApiTypes } from '../utils/apiTypes';

export const getDevicesList = () => (dispatch: Dispatch<AppActions>): Promise<ApiTypes> =>
  new Promise((resolve, reject) =>
    api.devices
      .getDevicesList()
      .then(res => {
        dispatch({
          type: GET_DEVICES_LIST,
          payload: {
            ...res.data.data,
          },
        });
        return resolve(res.data.data);
      })
      .catch(err => reject(err)),
  );

export const getAvailableTimezones = () => (dispatch: Dispatch<AppActions>): Promise<ApiTypes> =>
  new Promise((resolve, reject) =>
    api.devices
      .getAvailableTimezones()
      .then(res => {
        dispatch({
          type: GET_AVAILABLE_TIMEZONES,
          payload: {
            ...res.data,
          },
        });
        return resolve(res.data);
      })
      .catch(err => reject(err)),
  );

export const updateDeviceTimezone = (data: { unitSerial: string; value: string }) => (
  dispatch: Dispatch<AppActions>,
): Promise<ApiTypes> =>
  new Promise((resolve, reject) =>
    api.devices
      .updateTimezone(data)
      .then(res => {
        dispatch({
          type: UPDATE_DEVICE_TIMEZONE,
          payload: {
            ...res.data.data,
          },
        });
        return resolve(res.data.data);
      })
      .catch(err => reject(err)),
  );
